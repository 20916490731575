import Landing from "../views/Home/Home";
import Cart from "../views/Home/Cart";
import Products from "../views/Home/Products";
import Coming from "../views/Home/Coming";
import NoShop from "../views/Shop/Noshop";
import ShopDetails from "../views/Shop/Details";
import ShopSettings from "../views/Shop/Settings";
import SwitchShop from '../views/Shop/SwitchShop'
import Mail from "../views/Shop/Mail";
import LeadCapture from '../views/LeadCapture/LeadCapture';
import WhatsAppLead from '../views/LeadCapture/WhatsAppLead';
import Subscribers from '../views/Subscribers/Subscribers';
import AbandonedCarts from '../views/Home/AbandonedCarts'

const baseRoute = "/home";

export const routes = [
    {
        path: baseRoute,
        exact: true,
        component: Landing
    },
    {
        path: `${baseRoute}/cart`,
        component: Cart
    },
    {
        path: `${baseRoute}/products`,
        component: Products
    },
    {
        path: `${baseRoute}/noshop`,
        component: NoShop
    },
    {
        path: `${baseRoute}/details`,
        component: ShopDetails
    },
    {
        path: `${baseRoute}/settings`,
        component: ShopSettings
    },
    {
        path: `${baseRoute}/mail`,
        component: Mail
    },
    {
        path: `${baseRoute}/lead`,
        exact:true,
        component: LeadCapture
    },
    {
        path: `${baseRoute}/lead/whatsapp`,
        component:WhatsAppLead
    },
    {
        path: `${baseRoute}/campaigns`,
        component: Coming
    },
    {
        path: `${baseRoute}/shops`,
        component: SwitchShop
    },
    {
        path: `${baseRoute}/subscribers`,
        component: Subscribers
    },
    {
        path: `${baseRoute}/carts`,
        component: AbandonedCarts
    }
];


export const Headers = ["access-token", "client", "content-type", "expiry", "token-type", "uid"];

export const Pagination  = 15;