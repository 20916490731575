export const API_RESPONSE= "API_RESPONSE";
export const RELOAD_USER= "RELOAD_USER";
export const LOADING_START = "LOADING_STARTED";
export const LOADING_END = "LOADING_END";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const DASHBOARD_LOADED = "DASHBOARD_LOADED";
export const GET_ABANDON_CART_DATA = "GET_ABANDON_CART_DATA";
export const ABANDON_CART_LOADED = "ABANDON_CART_LOADED";
export const ABANDON_CART_UPDATED = "ABANDON_CART_UPDATED";
export const GET_ABANDON_PRODUCTS_DATA = "GET_ABANDON_PRODUCTS_DATA";
export const ABANDON_PRODUCTS_LOADED = "ABANDON_PRODUCTS_LOADED";
export const ABANDON_PRODUCTS_UPDATED = "ABANDON_PRODUCTS_UPDATED";
export const SIGNUP_USER_REQUEST = "SIGNUP_USER_REQUEST";;
export const VERIFY_USER_REQUEST= "VERIFY_USER_REQUEST";
export const SIGNIN_USER_REQUEST = "SIGNIN_USER_REQUEST";
export const REFRESH_USER_DATA = "REFRESH_USER_DATA";
export const FIND_ACTIVE_SESSION= "FIND_ACTIVE_SESSION";
export const AUTH_USER_FOUND = "AUTH_USER_FOUND";
export const INVALID_AUTH = "INVALID_AUTH";
export const SAVE_USER = "SAVE_USER";
export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const UPLOAD_SHOP_IMAGE = "UPLOAD_SHOP_IMAGE";
export const SHOP_GET_DETAILS = "SHOP_GET_DETAILS";
export const SHOP_UPDATE_DETAILS = "SHOP_UPDATE_DETAILS";
export const SHOP_UPDATED = "SHOP_UPDATED";
export const MAIL_GET_INFO = "MAIL_GET_INFO";
export const MAIL_INFO_FETCHED = "MAIL_INFO_FETCHED";
export const MAIL_GET_ALL_MAILS = "MAIL_GET_ALL_MAILS";
export const MAIL_SET_ALL_MAILS = "MAIL_SET_ALL_MAILS";
export const MAIL_CREATE_ALL_MAILS = "MAIL_CREATE_ALL_MAILS";
export const MAIL_ALL_MAILS_FETCHED = "MAIL_ALL_MAILS_FETCHED";
export const MAIL_ALL_MAILS_UPDATED = "MAIL_ALL_MAILS_UPDATED";
export const CREATE_LEAD_WIDGET="CREATE_LEAD_WIDGET";
export const STORE_ADD_NEW_WIDGET="STORE_ADD_NEW_WIDGET";
export const FETCH_ALL_LEADS="FETCH_ALL_LEADS";
export const STORE_UPDATE_FETCHED_LEADS="STORE_UPDATE_FETCHED_LEADS";
export const UPDATE_LEAD="UPDATE_LEAD";
export const STORE_UPDATE_UPDATED_LEADS="STORE_UPDATE_UPDATED_LEADS";
export const UPDATE_FOLLOWUP = "UPDATE_FOLLOWUP";
export const STORE_UPDATE_FOLLOWUP="STORE_UPDATE_FOLLOWUP";
export const FETCH_EMAIL_TEMPLATE_INFO="FETCH_EMAIL_TEMPLATE_INFO";
export const STORE_UPDATE_FETCH_TEMPLATE="STORE_UPDATE_FETCH_TEMPLATE";
export const CREATE_EMAIL_FOLLOWUP="CREATE_EMAIL_FOLLOWUP";
export const STORE_ADD_NEW_FOLLOWUP="STORE_ADD_NEW_FOLLOWUP";
export const FETCH_ALL_USER_DETAILS="FETCH_ALL_USER_DETAILS";
export const STORE_FETCH_ALL_USER_DETAILS="STORE_FETCH_ALL_USER_DETAILS";
export const MAKE_SHOP_DEFAULT="MAKE_SHOP_DEFAULT";
export const UPDATE_DEFAULT_SHOP="UPDATE_DEFAULT_SHOP";
export const SWITCH_SHOP="SWITCH_SHOP";
export const SHOW_PWD_POPUP="SHOW_PWD_POPUP";
export const HIDE_PWD_POPUP="HIDE_PWD_POPUP"
export const FETCH_SUBSCRIBERS="FETCH_SUBSCRIBERS";
export const STORE_ADD_FETCHED_SUBSCRIBERS="STORE_ADD_FETCHED_SUBSCRIBERS";
export const STORE_UPDATE_FETCHED_SUBSCRIBERS="STORE_UPDATE_FETCHED_SUBSCRIBERS";
export const SHOW_INNER_PAGE_LOADER="SHOW_INNER_PAGE_LOADER";
export const HIDE_INNER_PAGE_LOADER="HIDE_INNER_PAGE_LOADER";
export const LOADING_COMPLETED="LOADING_COMPLETED";
export const CARTS_LIST = "CARTS_LIST";
export const UPDATE_CARTS_LIST = "UPDATE_CARTS_LIST";
export const LOADED_CARTS_LIST = "LOADED_CARTS_LIST";
export const SHOW_POPUP = "SHOW_POPUP";

export const TOGGLE_CART_LIST_POPUP = "TOGGLE_CART_LIST_POPUP";
export const CART_ITEM_UPDATED = "CART_ITEM_UPDATED";
export const GET_CARTS_ITEM = "GET_CARTS_ITEM";
export const LOADED_CART_ITEM = "LOADED_CART_ITEM";
export const ADD_TO_ACTIVE_CART = "ADD_TO_ACTIVE_CART";
export const GET_ACTIVE_CART="GET_ACTIVE_CART";
export const UPDATE_ACTIVE_CART ="UPDATE_ACTIVE_CART";

export const SET_CURRENT_SHOP_ID="SET_CURRENT_SHOP_ID";
export const CLEAR_CURRENT_SHOP_ID="CLEAR_CURRENT_SHOP_ID";
export const CLEAR_SUBSCRIBERS="CLEAR_SUBSCRIBERS";
export const EMAIL_SUBSCRIBER_LIST="EMAIL_SUBSCRIBER_LIST";
export const REMOVE_SHOP="REMOVE_SHOP";
export const STORE_UPDATE_REMOVE_SHOP="STORE_UPDATE_REMOVE_SHOP";
export const STORE_UPDATE_SHOP_DETAILS="STORE_UPDATE_SHOP_DETAILS";
export const RESET_STATE="RESET_STATE";
export const SET_QUERY_STRING="SET_QUERY_STRING";
export const RESET_QUERY_STRING="RESET_QUERY_STRING";