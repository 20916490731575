import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "../api";
import * as types from "../constants";
import { get } from "lodash";
import { destroySession, setSaveHeaders, errorHandler } from "../../utils";


export function* signupUser(action) {
    try {
        let user = yield call(Api.signUpUser, action.payload);
        if (get(user, "data.status", "") === "success") {
            setSaveHeaders(user, false);
            action.history.replace({
                pathname: '/auth/verify',
                state: {
                    email: action.payload.email,
                    from: "Login"
                }
            });
        }else{
            errorHandler(user.data);
        }
    } catch (e) {
        errorHandler(e);
    }
}


export function* reloadUser(action) {
    try{
        const userdetails = yield select((state)=>state.Shop.user),
              history = get(action,"history");

        let user = yield call(Api.reloadUser, userdetails.id);

        if (get(user, "data.status", "") === "success") {
            destroySession();
            setSaveHeaders(user);
            yield put({
                type: types.AUTH_USER_FOUND,
                payload: get(user, "data.data")
            });
            history.replace({
                pathname: '/home'
            });
        }else{ errorHandler(user)}
    }catch(e){ errorHandler(e) }
}

export function* verifyUser(action) {
    try {
        let verify = yield call(Api.verifyUser, action.payload);
        if (get(verify, "data.status", "") === "success") {
            setSaveHeaders(verify);
            yield put({
                type: types.AUTH_USER_FOUND,
                payload: get(verify, "data.data")
            });
            action.history.replace({
                pathname: '/home'
            });
        }else{
            errorHandler(verify);
        }
    } catch (e) {
        errorHandler(e);
    }
}

export function* signInUser(action) {
    try {
        let user = yield call(Api.signInUser, action.payload);
        if (get(user, "data.status", "") === "success") {
            destroySession();
            setSaveHeaders(user);
            yield put({
                type: types.AUTH_USER_FOUND,
                payload: get(user, "data.data")
            });
            action.history.replace({
                pathname: '/home'
            });
        }else{
            errorHandler(user)
        }

    } catch (e) {

        errorHandler(e);

        if (get(e, "response.data.otp_token", "")) {
            action.history.replace({
                pathname: "/auth/verify",
                state: {
                    email: action.payload.email,
                    token: get(e, "response.data.otp_token"),
                    from: "Login"
                }
            })
        }
    }
}


export default function* Auth() {
    yield takeLatest(types.SIGNUP_USER_REQUEST, signupUser);
    yield takeLatest(types.SIGNIN_USER_REQUEST, signInUser);
    yield takeLatest(types.VERIFY_USER_REQUEST, verifyUser);
    yield takeLatest(types.RELOAD_USER, reloadUser);
}