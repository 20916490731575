import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getFollowUps, getAllMails, setAllEmails, createAllEmails, updateFollowup,createNewFollowup,fetchEmailTemplateInfo } from "../../store/actions";
import { caller } from "../../utils";
import { map } from "lodash";
import FollowUp from "../../components/FollowUp/Followup";
import MailDetails from "../../components/MailDetails";

const Mail = ({followups, getFollowups, setAllEmails, createAllEmails, getAllMails, mails,updateFollowup,createNewFollowup,fetchEmailTemplateInfo}) => {

    useEffect(() => {
        caller(getFollowups);
        caller(getAllMails);
    }, []);
    const demoData= [
        {
          "id":"a",
          "category": "cart_abandon",
          "name": "Followup 1",
          "sequence": 1,
          "status": 1,
          "time_delay": 15,
          template:null
        },
        {
          "id":"b",
          "category": "cart_abandon",
          "name": "Followup 2",
          "sequence": 2,
          "status": 1,
          "time_delay": 15,
          template:null
        },
        {
          "id":"c",
          "category": "cart_abandon",
          "name": "Followup 3",
          "sequence": 3,
          "status": 1,
          "time_delay": 15,
          template:null
        }
      ];

      const allFollowups=demoData.map(demo=>{
        var result=followups.filter(followup=> followup.sequence==demo.sequence);
            return result.length?result[0]:demo
         });
    return (
        <div className="mail-settings uk-width-5-6@m">
            <div className="uk-padding uk-border-rounded box-shadow-medium uk-background-default">
                <h3 className="e-heading">My templates (3)</h3>
                <div className="e-subheading uk-width-2-5">
                    Customer followup mail template and sending address.
                    Can change the details anytime
                </div>
                <div className="mail_settings uk-margin-medium-top uk-flex uk-flex-between">
                    <div>
                        <ul className="choose_temps_tabs uk-margin-small-top" uk-tab="">
                            {map(
                                allFollowups, (followup) =>
                                    <li key={followup.id}>
                                        <a href="##" className="uk-text-capitalize">{followup.name}</a>
                                    </li>
                            )}
                        </ul>
                        <ul className="uk-switcher uk-margin">
                            {allFollowups.map(followup=> <FollowUp key={followup.id} followup={followup} updateFollowup={updateFollowup} fetchEmailTemplateInfo={fetchEmailTemplateInfo} createNewFollowup={createNewFollowup} />)}
                        </ul>
                    </div>
                    <div><MailDetails createAllEmails={createAllEmails} setAllMails={setAllEmails} mails={mails}/></div>
                </div>
            </div>
        </div>
    )
};


const mapStateToProps = ({Shop}) => ({
    followups: Shop.followups,
    mails: Shop.mails
});

const mapDispatchToProps = {
    getFollowups: getFollowUps,
    getAllMails: getAllMails,
    setAllEmails: setAllEmails,
    createAllEmails: createAllEmails,
    updateFollowup:updateFollowup,
    createNewFollowup:createNewFollowup,
    fetchEmailTemplateInfo:fetchEmailTemplateInfo
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Mail);
