import * as types from "../constants";

const initialState = {
    loading: false,
    activeCart: [],
    dashData: {
        recent_amount: 0,
        recent_count: 0,
        active_amount: 0,
        active_count: 0
    },
    dashGraph: {
        count: [],
        amount: []
    },
    abandonCart: {
        amount_list: []
    },
    abandonCartItems: {
        cart_items: []
    },
    showCartListPopup: false,
    abandonCartsList: [],
    abandonProducts: {
        count_list: [],
        amount_list: []
    },
    abandonProductsGraph: {
        count_list: [],
        amount_list: []
    },
    queryString:{
        shopId:"",
        platform:"",
        email:""
    },
    response: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOADING_START:
            return {...state, loading: true};
        case types.LOADING_END:
            return {...state, loading: false};
        case types.DASHBOARD_LOADED:
            return {
                ...state,
                dashData: action.payload.dash,
                dashGraph: action.payload.graph
            };
        case types.UPDATE_CARTS_LIST:
                    return {
                        ...state,
                        abandonCartsList: [...state.abandonCartsList, ...action.payload]
                    };
        case types.LOADED_CARTS_LIST:
                    return{
                        ...state,
                        abandonCartsList: action.payload
                    };
        case types.ABANDON_CART_LOADED:
            return {...state, abandonCart: action.payload};
        case types.ABANDON_CART_UPDATED:
            return {
                ...state, abandonCart: {
                    amount_list: [...state.abandonCart.amount_list, ...action.payload.amount_list]
            }};            
        case types.ABANDON_PRODUCTS_LOADED:
            return {
                ...state,
                abandonProducts: action.payload.products,
                abandonProductsGraph: action.payload.graph
            };
        case types.ABANDON_PRODUCTS_UPDATED:
            return {
                    ...state,
                    abandonProducts: {
                        count_list : [...state.abandonProducts.count_list, ...action.payload.products.count_list],
                        amount_list: [...state.abandonProducts.amount_list, ...action.payload.products.amount_list]                        
                    },
                    abandonProductsGraph: action.payload.graph
                };
        case types.API_RESPONSE:
            return {
                ...state,
                response: action.payload
            };
            case types.TOGGLE_CART_LIST_POPUP:
                    return {
                      ...state,
                    activeCart: action.payload,
                    showCartListPopup: !state.showCartListPopup
                    };
            case types.UPDATE_ACTIVE_CART:
                    return{
                    ...state,
                    activeCart: action.payload
                    }
            case types.SET_QUERY_STRING:
                return{
                    ...state,
                    queryString:{
                        ...state.queryString,...action.payload
                    }
                }
            case types.RESET_QUERY_STRING:
                return{
                    ...state,
                    queryString:{
                        ...state.queryString,...action.payload
                    }
                }
            default:
            return state;
    }
};
export default reducer;
