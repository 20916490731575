import axios from "axios";
import { Pagination } from "../../constants/Global";

const ajaxConf = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 20000,
    headers: {"Content-Type": "application/json"}
};

const api = axios.create(ajaxConf);

const reports = "saas/reports";
const shops = "saas/shops";
const carts = "saas/carts"

export function setToken(headers) {
    Object.assign(api.defaults.headers.common, headers);
}


export const getDashboardData = (scale ={scale:"week"}) =>
    api.post(`${reports}/dashboard`, {
        report: {...scale}
    });

export const getDashboardGraph = (scale ={scale:"week"}) =>
    api.post(`${reports}/graph`, {
        report: {
            ...scale,
            graph: ["cart_count", "cart_amount"]
        }
    });
export const getCartList = (scale = {scale:"week"},page= 1,size = Pagination) =>
    api.post (`${carts}/fetch-records`, {
        cart: {
            ...scale,
            page,
            size
          }
    });

export const getAbandonCartList = (scale ={scale:"week"},page = 1,size = Pagination) =>
    api.post(`${reports}/abandon-carts-list`, {
        report: {
            ...scale,
            list: ["cart_count", "cart_amount", "cart_recent"],
            page,
            size
        }
    });

export const getAbandonProducts = (scale ={scale:"week"},page = 1, size = Pagination) =>
    api.post(`${reports}/abandon-products-list`, {
        report: {
            ...scale,
            list: ["cart_count", "cart_amount"],
            page,
            size
        }
    });

export const getAbandonProductsGraph = (scale ={scale:"week"}) =>
    api.post(`${reports}/abandon-products`, {
        report: {
            ...scale,
            graph: ["cart_count", "cart_amount"]
        }
    });

export const signUpUser = (userDetails = {}) =>
    api.post("auth", {
        ...userDetails
    });

export const verifyUser = (userDetails = {}) =>
    api.post("auth/confirm", {
        ...userDetails
    });

export const signInUser = (userDetails = {}) =>
    api.post("auth/sign_in", {
        ...userDetails
    });


export const resendOTP = (email = "") =>
    api.post("auth/request-otp", {
        service: "verification",
        email
    });


export const resetEmail = (email = "") =>
    api.post("auth/password", {
        email
    });

export const resetPassword = (reset) =>
    api.put("auth/password", {
        ...reset
    });

export const verifyStore = (url, platform = 'woocommerce') =>
    api.post(`${shops}/plugin-status`, {
        "shop": {
            "domain": url,
            "platform": platform
        }
    });

export const getShopDetails = () =>
    api.post(`${shops}/details`);


export const updateShopDetails = (data) =>
    api.put(`${shops}/update-details`, {
        "shop": data
    });


export const getPluginLink = (url, platform = 'woocommerce') =>
    api.post(`${shops}/activate-link`, {
        "shop": {
            "domain": url,
            "platform": platform
        }
    });

export const updateLogo = (data) =>
    api.put(`${shops}/upload-logo`, data);

export const getShopMailSettings = () =>
    api.post(`${shops}/mail-settings`);

export const getAllMails = () =>
    api.get(`saas/emails`);

export const createNewMails = (payload) =>
    api.post(`saas/emails`, {
        email: payload
    });

export const updateMails = (id,payload) =>
    api.put(`saas/emails/${id}`, {
        email: payload
    });

export const saveUser = (id, name) =>
    api.put(`saas/users/${id}`, {
        "user": {name}
    });

export const reloadUser = (id) =>
    api.get(`saas/users/${id}`);

export const uploadAvatar = (id, form) => {
    const x = axios.create({
        ...ajaxConf,
        headers: {
            ...api.defaults.headers.common,
            "Content-Type": "multipart/form-data"
        }
    });
    return x.put(`saas/users/${id}/upload-avatar`, form);
};

export const uploadShopImage = (form) => {
    const x = axios.create({
        ...ajaxConf,
        headers: {
            ...api.defaults.headers.common,
            "Content-Type": "multipart/form-data"
        }
    });
    return x.put(`saas/shops/upload-logo`, form);
};

export const updateFollowup = (id,payload) =>
    api.put(`saas/email_followups/${id}`, {
        email_followup: payload
    });

export const fetchEmailTemplateInfo=(id)=>
    api.get(`saas/email_templates/${id}`)

//Email followup create post request
export const createNewFollowup=(payload)=>
    api.post('saas/email_followups',{
        email_followup: payload
    })

export const fetchAllUserDetails=()=>{
    return api.get('saas/users/details');
}

export const makeShopDefault=(id)=>{
    return api.post(`saas/members/${id}/make-default`)
}

export const createLeadWidget=(payload)=>
    api.post('saas/apps',{
        app:payload
});

// To fetch all Leads
export const fetchAllLeads=()=>
    api.get('saas/apps');

// Update Lead
export const updateLead=(id,data)=>
    api.put(`saas/apps/${id}`,data)
//Api to fetch all Wishlist Records/Subscribers
export const fetchSubscribers=(scale = {scale:"week"},page= 1,size = Pagination)=>
    api.post('saas/wishlists/fetch-records',{
        wishlist:{
            ...scale,
            page,
            size
        }
    });
// Featch Cart data
export const getActiveCart = (id) =>
    api.post(`${carts}/${id}/fetch-details`)

//Send Subscriber List to Email

export const emailSubscribersList=(data)=>
    api.post('saas/wishlists/send-email',{
        wishlist:{
            ...data
        }
})

export const removeShop=(id)=>
    api.delete(`saas/members/${id}`)