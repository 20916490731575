import React,{useState} from 'react'
import Img from '../../constants/Img';
import ReactTooltip from 'react-tooltip'
const ShopPreview = ({title,titleColor,buttonColor,tooltip,btnText,btnText1,btnText2,widgetName}) => {
    const [btnStatus,setButtonStatus]=useState(0);
    const changeButton=()=>{
        setButtonStatus(btnStatus===0?1:btnStatus===1?2:0);
    }
    return (
            <div className="uk-card uk-card-body uk-card-default preview-card" style={{height:"auto"}}>
                            <ReactTooltip/>
                            <h5 className="uk-card-title">Flower Design Stud
                            </h5>
                            <div className="uk-grid uk-child-width-expand@s uk-flex-middle">
                            <div className="uk-width-1-2">
                                <img className="product-img" src={Img.productImage}/>
                            </div>
                            <div className="uk-width-1-2 btn-container">
                            <div className="prev-price">₹139</div>
                            {widgetName==="email"&&
                            <React.Fragment>
                            <div className="prev-title" style={{color:titleColor}}>{title}<span className="tooltip-icon" uk-icon="info" data-tip={tooltip}></span></div>
                            <button className={`uk-button uk-button-default uk-button-small ${btnStatus===0?'':'uk-hidden'}`}
                                onClick={changeButton}
                                style={{background:buttonColor}}
                            >{btnText1}</button>
                            <div className={`${btnStatus===1?'':'uk-hidden'}`}><div><input className="uk-input" type="text" placeholder="Eg:name@email.com" disabled=""/></div><div className=" btn-holder"><button className="uk-button uk-button-default uk-button-small"
                            onClick={changeButton}
                            style={{background:buttonColor}}
                            >{btnText2}</button></div></div>
                            <div className={`uk-text-small success-msg uk-text-success ${btnStatus===2?'':'uk-hidden'}`}
                                onClick={changeButton}
                            >
                                Request Success..
                            </div>
                            </React.Fragment>
                            }
                            {
                            widgetName==="whatsapp"&&
                            <button className="uk-button uk-button-default uk-button-small" style={{
                                color:titleColor
                            }}>
                                <a>
                                    <img className="whatsapp-img" src={Img.whatsapp}/>
                                </a>
                                {btnText}</button>
                            }
                            <div className="cart-count">
                                <a>
                                    <img src={Img.decrement}/>
                                </a>
                                <span>01</span>
                                <a>
                                    <img src={Img.increment}/>
                                </a>
                            </div>
                            <button className="uk-button uk-button-default uk-button-small add-cart-btn">
                                Add to Cart
                            </button>
                            </div>
                            </div>
            </div>
    )
}

export default ShopPreview
