import React,{useState,useEffect} from 'react';
import {SketchPicker} from 'react-color'

const ColorPick = ({name,label,color,handleChangeColor}) => {
    const [col,setColor]=useState(color);
    const [displayColorPicker,setDisplayColorPicker]=useState(false);
    const handleChangeComplete = (color) => {
        setColor(color.hex);
      };
      const handleShowColorPicker=(e)=>{
        setDisplayColorPicker(true);
    }
    const handleCloseColorPicker=()=>{
        setDisplayColorPicker(false);
    }
    useEffect(()=>{
        handleChangeColor(name,col);
    },[col])

    return (
        <div>
            <label className="uk-form-label">{label}</label>
                <div className="uk-input uk-form-width-small uk-form-small color-input"
                        onClick={handleShowColorPicker}
                        style={{background:col}}
                />
                {displayColorPicker&&
                <div className="color-picker-palette">
                    <div className="color-picker-cover"  onClick={handleCloseColorPicker}>          
                    </div>
                    <SketchPicker onChangeComplete={handleChangeComplete} color={col}/>
                </div>
                }
        </div>
    )
}

export default ColorPick
