import React, { useEffect } from "react";
import { get } from "lodash";
import { declare } from "../../utils";
import dateFormat from "dateformat";
import Img from "../../constants/Img";
import { caller } from "../../utils";
import { connect } from "react-redux";
function AbandonCartListItem({ product, getCartItem, toggleCartListPopup }) {
  
  const displayCartItems = () => {
    
    caller(getCartItem, product.id)

    toggleCartListPopup()
  
  }

  return (

    <li className="uk-flex cart-item abn-cart-item uk-border-rounded box-shadow-medium uk-padding-small-cart uk-padding-small"  >
      <div className="name-container" >
        <div className="details-header">
          <div className="cart-item-name">
            <img
              src={Img.cartLogo}
              width="14"
              className="uk-img cart-image"
              alt=""
            />
            {"ID:100" + product.id}
          </div>
          <div className="cart-item-amount">
            <span>{"Created " + dateFormat(product.created_at, "dd mmm")}</span>
          </div>
          <div className="cart-item-amount">
            <span>{"Updated " + dateFormat(product.updated_at, "dd mmm")}</span>
          </div>
        </div>
      </div>
      <div>
        <div className="details-header">
          <div className="buyer-email">
            <img src={Img.usersmall} width="14" className="uk-img" alt="" />
            {declare(get(product, "customer.email"), "Visitor")}
          </div>
          <div className="buyer-name">
            {declare(get(product, "customer.name"), "Not Available")}
          </div>
          <div className="buyer-name">
            {declare(get(product, "customer.ip"), "IP:194 101 562 2")}
          </div>
        </div>
      </div>
      <div>
        <div className="details-header">
          <div className="count">Count</div>
          <div className="qty">{product.total_quantity + " items"}</div>
        </div>
      </div>
      <div>
        <div className="details-header" onClick={displayCartItems}>
          <div className="total-text">Total amount</div>
          <div className="total-amount">$ {product.total_amount + ".00"}</div>
          <a>
          <li>
            <img
              src={Img.rightArrow}
              width="5"
              className="uk-img arrow-image"
              alt=""
            />
          </li>
          </a>
        </div>
      </div>
    </li>
  );
}

const mapStateToProps = ({ Home }) => ({
  cartsItem: Home.activeCart
});




export default connect(
  mapStateToProps
)(AbandonCartListItem);