import React from "react";
import Img from "../../constants/Img";
// import { get } from "lodash";
import classNames from "classnames";
import dateFormat from "dateformat";
// deleted, abandoned, recovered

function CartListItem({item,index}) {
  let status = item.flag_status;
  let statusCheck;
  let flagImg=Img.FlagAbandoned;
  switch(status){
    case 2:
        statusCheck='checkout';
        break;
    case 4:
        statusCheck='recovered';
        flagImg=Img.FlagRecovered;
        break;
    case 6:
        statusCheck='active';
        break;
    case 7:
        statusCheck="abandoned";
        break;
    case 8:
        statusCheck='removed';
        break;
    default:
        statusCheck='abandoned';
  }
    return(
        <div className={classNames('items', 'item-1', 'uk-flex',  statusCheck ) }>
            <div className="uk-flex-1">
              <p>#{index + 1}</p>
            </div>
            <div className="uk-flex-4 uk-grid-small" uk-grid="masonry: true">
              <div className="uk-width-auto">
                <img src={item.image} className="uk-img" width="46px" alt=""/>
              </div>
              <div className="uk-width-expand">
                <p className="uk-text-truncate uk-margin-remove uk-text-bold productName">
                  {item.name}
                </p>
                <p className="uk-text-muted">{'$ ' + item.total+'.00'}</p>
              </div>
            </div>
            <div className="uk-flex-3">
              <p className="uk-text-center">{dateFormat(item.created_at, "dd mmm h:MM TT")}</p>
            </div>
            <div className="uk-flex-1">
              <p className="uk-text-right">
                <span className="uk-text-muted uk-text-small">Qty</span> {item.quantity}
              </p>
            </div>
            <div className="uk-flex-1-5">
              <p className="">
                <img src={flagImg}/>
                &nbsp; {statusCheck}
              </p>
            </div>
          </div>
    );
}

export default CartListItem;