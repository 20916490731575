import React from "react";
import { get } from "lodash";
import { declare } from "../../utils";
import dateFormat from "dateformat";
import Img from "../../constants/Img";

function AbandonProduct(props) {
  const { product } = props;
  let status = product.flag_status;
  let statusCheck;
  let flagImg=Img.FlagAbandoned;
  switch(status){
    case 2:
        statusCheck='checkout';
        break;
    case 4:
        statusCheck='recovered';
        flagImg=Img.FlagRecovered;
        break;
    case 6:
        statusCheck='active';
        break;
    case 7:
        statusCheck="abandoned";
        break;
    case 8:
        statusCheck='removed';
        break;
    default:
        statusCheck='abandoned';
  }
  return (
    <li className="uk-flex uk-flex-middle cart-item prd-item uk-border-rounded box-shadow-medium uk-padding-small-cart">
      <div className="details-header image-header">
        <img src={product.image} className="uk-img item-image" alt="" />
      </div>
      <div className="name-container">
        <div className="details-header ">
          <div className="cart-item-name">{product.name}</div>
          <div className="cart-item-amount">
            $ {product.total.toLocaleString()}
          </div>
        </div>
        {/* <div className="more-info">
          More info <img src={Img.more} className="uk-img" alt="" />
        </div> */}
      </div>
      <div>
        <div className="details-header">
          <div className="buyer-name">
            <img src={Img.usersmall} width="14" className="uk-img" alt="" />
            {declare(get(product, "customer.name"), "UserName")}
          </div>
          <div className="buyer-email">
            {declare(get(product, "customer.email"), "useremail@gmail.com")}
          </div>
        </div>
        {/* <div className="buyer-footer">
          <span className="send-mail">Send mail</span>{" "}
          <span className="user-details">Details</span>
        </div> */}
      </div>
      <div>
        <div className="details-header">
          <span className="qty">Qty</span> {product.quantity}
        </div>
        {/* <div className="chat-message">
          <img src={Img.messenger} className="uk-img" alt="" />
          Message
        </div> */}
      </div>
      <div>
        <img src={Img.datetime} className="uk-img" alt="" />
        <span>{dateFormat(product.created_at, "dd mmm,hh:MMtt")}</span>
      </div>
      <div>
        <div className="details-header">
          <img src={Img.flag} className="uk-img" alt="" />
          {statusCheck}
        </div>
        {/* <div className="remove-button">
          <img src={Img.trash} className="uk-img" alt="" />
          Remove
        </div> */}
      </div>
    </li>
  );
}

export default AbandonProduct;
