import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Img from "../../constants/Img";
import { get } from "lodash";
import { destroySession, errorHandler } from "../../utils";
function UserProfile({setVisibleProfile, user, saveUser, uploadAvatar, showPasswordPopUp, history, clearCurrentShopId}) {
    const [name, setName] = useState(user.name);
    const [isEditing, setIsEditing] = useState(false);

    let userRef = React.createRef();
    let inputElement = React.createRef();
    let nameInput = React.createRef();

    /*
    *  UseEffect callback below handles to hide userprofiel on outside click
    */

    useEffect(()=>{
        nameInput.current.focus();
        function outsideClick(e){
           if(!userRef.current.contains(e.target)){
            setVisibleProfile(false);
           }
        }
        document.addEventListener("mousedown", outsideClick);
        return function cleanupEvent(){
            document.removeEventListener("mousedown", outsideClick);
        }
    },[setVisibleProfile,userRef,nameInput]);


    const handleImageChange = (e) => {

        const types=['image/gif','image/png','image/jpeg','image/bmp','image/webp'];
        if (e.target.files.length) {
            const file = new FormData();
            file.append("avatar", e.target.files[0]);
            if(types.includes(e.target.files[0].type))
            {
                uploadAvatar({
                    id: user.id,
                    image: file
                });
            }
            else{
                errorHandler({message:"Invalid File Type"});
            }
        }
    };


    const onNameFocusOut = useCallback(() => {
        setIsEditing(false);
        saveUser({id: user.id, name: name})
    }, [name,user,saveUser]);


    const onLogout = useCallback(() => {
        destroySession();
        history.replace("/auth");
        clearCurrentShopId();
    }, [history]);




    const userImage = get(user, "avatar_url", Img.userbig) || Img.userbig;

    return (
        <div ref={userRef} className="e-user-nav uk-position-fixed">
            <div className="e-user-field">

                <img width={34} src={userImage} className="uk-img" alt=""/>

                <a href="##" uk-icon="chevron-up" onClick={(e) => {
                    e.preventDefault();
                    setVisibleProfile(false);
                }}>
                    <span className="user-name">{user.name}</span>
                </a>
            </div>
            <div className="user-image">
                <div className="e-upload-container">
                    <img className="e-user-icon"
                         src={userImage}
                         alt=""/>
                    <img onClick={() => inputElement.current.click()} className="e-upload" src={Img.camerablue} alt=""/>
                </div>
                <input
                    className="e-image-uploader"
                    ref={inputElement}
                    onChange={handleImageChange}
                    type="file"
                    accept="image/*"/>
            </div>

            <div className="e-user-input">
                <span>Full name</span>
                <div>
                    <p className={isEditing?"input-label uk-hidden":"input-label"}
                        onClick={()=>{setIsEditing(true);nameInput.current.focus()}}
                    >{name}</p>
                    <input
                        ref={nameInput}
                        className={!isEditing?"e-input-value uk-hidden":"e-input-value"}
                        value={name}
                        onBlur={onNameFocusOut}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            </div>

            <div className="e-user-input">
                <span>Email id</span>
                <div>
                    <input
                        className="e-input-value"
                        disabled={true}
                        value={user.email}/>
                </div>
            </div>


            <div className="verified-text">
                <img src={Img.tick} width={12} alt=""/>
                <span>Confirmed</span>
            </div>

            <div className="e-password" uk-toggle="target: #modal-example" onClick={()=>showPasswordPopUp()}>
                <span>Update Password </span>
                <span uk-icon="chevron-right"></span>
            </div>

            <div onClick={onLogout} className="e-logout">
                <span uk-icon="sign-out"/>
                <span className="logout-text">Logout</span>
            </div>

        </div>
    )
}


export default withRouter(UserProfile);
