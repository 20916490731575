import React, { useState } from "react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import classNames from "classnames";

function Nav({routes,location}) {
    //console.log('Routes',location)
    const [selIndex, setSelIndex] = useState(0);
    return (
        <div
            className="uk-card cart-nav border-round uk-card-default uk-card-small"
            uk-sticky="offset: 100;media: @m"
        >
            <div className="uk-width-1-1">
                <ul className="uk-nav uk-nav-default">
                    {map(routes, (route, index) => (
                        <li
                            key={index}
                            onClick={e => setSelIndex(index)}
                            className={classNames({
                                "uk-active": location.pathname===route.path
                            })}
                        >
                            <Link to={route.path}>{route.text}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Nav;
