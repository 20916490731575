import * as types from "../constants";
const initialState={
    showPwdPopUp:false,
    showInnerPageLoader:false,
    loadingCompleted:false
}
const reducer = (state = initialState, action) => {
    const {type,payload}=action;
    switch (type) {
        case types.SHOW_PWD_POPUP:
            return {...state,showPwdPopUp:true}
        case types.HIDE_PWD_POPUP:
            return {...state,showPwdPopUp:false}
        case types.SHOW_INNER_PAGE_LOADER:
            return {...state,showInnerPageLoader:true}
        case types.HIDE_INNER_PAGE_LOADER:
            return {...state,showInnerPageLoader:false}
        case types.LOADING_COMPLETED:
                return {...state,loadingCompleted:payload}
        default:
            return state;
    }
};
export default reducer;