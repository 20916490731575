import React, { useState, useCallback, useEffect } from "react";
import Img from "../../constants/Img";
import { verifyStore, getPluginLink } from "../../store/api";
import { reloadSession } from "../../store/actions";
import { errorHandler } from "../../utils";
import { get } from "lodash";
import { connect } from "react-redux";



/*
 *   LocalSotrage "Status" = "connecting" when a user intend to connect a shop
*/

const shopTypes = {
    no_shop: 0,
    shop_found: 1,
    shop_invalid: 2
};


function ShopBottomModifier({shopType, onClick,onReset, successClick}) {
    if (shopType === shopTypes.no_shop) {
        return (
            <React.Fragment>
                <button onClick={onClick} className="uk-button uk-margin-top  o-button">Check status</button>
            </React.Fragment>
        )
    } else if (shopType === shopTypes.shop_invalid) {
        return (
            <React.Fragment>
                <div className="not-verified-text">
                    <img src={Img.cancel} width={13} alt=""/>
                    <span>Plugin status check failed</span>
                </div>
                <a href="https://wordpress.org/plugins/recover-woocommerce-abandoned-cart/"  target="_blank"
                        className="uk-button uk-margin-top  o-button install-plugin"
                        onClick={onReset}
                        >
                    Install Plugin
                </a>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <div className="verified-text">
                    <img src={Img.tick} width={12} alt=""/>
                    <span>Plugin connected and verified</span>
                </div>
                <button onClick={successClick} className="uk-button uk-margin-top  o-button">Connect Shop</button>
            </React.Fragment>
        )
    }
}


function Shop({reloadSession, history}) {

    const [url, setUrl] = useState("https://");
    const [shopFound, setShopFound] = useState(shopTypes.no_shop);


    const checkStatus = useCallback(() => {
        verifyStore(url).then((result) => {
            const status = get(result, "data.status") === "success" ? shopTypes.shop_found : shopTypes.shop_invalid;
            setShopFound(status)
        }).catch((e) => {
            errorHandler(e);
        });
    }, [url]);

    const setToInitial=useCallback(() => {
            setShopFound(shopTypes.no_shop)
    });

    const successCallback = useCallback(() => {
        getPluginLink(url).then((result) => {
            if (get(result, "data.status") === 'success'){
                localStorage.setItem("status","connecting");
                window.location = get(result, "data.url");
            }
        });
    }, [url]);

    useEffect(()=>{
        if(localStorage.getItem("status") === "connecting" ){
            reloadSession(history);
            localStorage.removeItem("status");
        }
    },[history,reloadSession]);



    return (
        <div className="uk-width-1-1@m">
            <div className="shop-container uk-text-center uk-align-center uk-margin-top uk-width-3-5">
                <img alt="" width="250" src={Img.starter}/>
                <h2>Let's start</h2>
                <p>
                    The Abandoned Cart plugin has to be installed and activated to connect the store successfully. You
                    can check the plugin status by clicking on the status check button
                </p>
                <form className="url-check uk-flex-center uk-flex" action="" method="post" style={{height: "50px"}} onSubmit={(e)=>e.preventDefault()}>
                    <button type="button" name="button">
                        <span uk-icon="icon: link;"/>
                    </button>
                    <input onChange={(e) => setUrl(e.target.value)} type="url" name="" value={url}/>
                </form>
                <ShopBottomModifier shopType={shopFound} onClick={checkStatus} onReset={ setToInitial } successClick={successCallback}/>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    reloadSession
}

export default connect(null,mapDispatchToProps)(Shop);