import React,{useState,useEffect} from 'react'
import Img from '../../constants/Img'
import Switch from 'react-switch';
import {errorHandler} from '../../utils'
import {fetchAllLeads,updateLead,createLeadWidget} from '../../store/actions';
import {connect} from 'react-redux';
import CustomizeWidget from '../../components/LeadCapture/CustomizeWidget';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const WhatsAppLead = ({apps,fetchAllLeads,createLeadWidget,updateLead}) => {
    const[phNo,setPhNo]=useState("");
    const [enabled,setEnabled]=useState(false);
    const [didMount,setDidMount]=useState(false);
    const [isCreated,setIsCreated]=useState(false);
    const [whatApp,setwhatapp]=useState({});
    const [showCustomizeWid,setShowCustomizeWid]=useState(false);
    
    useEffect(()=>{
        fetchAllLeads();
    },[]);

    useEffect(()=>{
        let whatApps=apps.filter(app=>app.platform==="whatsapp");
            whatApps.sort(function(a, b){
                return a.id-b.id
            });
        if(whatApps.length){
            setIsCreated(true);
            setwhatapp(whatApps[0]);
            setEnabled(whatApps[0].status===2?true:false);
            setPhNo(whatApps[0].settings.mobile_number?whatApps[0].settings.mobile_number:"");
            
        }
    },[apps]);

    useEffect(() => {
        if(didMount&&!isCreated)
            createLeadWidget({
                    "name": "WhatsApp Product Chat",
                    "desc": "WhatsApp Product Chat",
                    "platform": "whatsapp",
                    "category": "leads",
                    "status": 2,
                    "settings": {
                        "mobile_number":phNo,
                        "btn_text": "Chat Now",
                        "desc": "Chat about this specific product by clicking here"
                  }
            })
        else if(didMount)
            updateLead(whatApp.id,{status:enabled?2:1,settings: {
                mobile_number:phNo}
            });
        else
            setDidMount(true);
    }, [enabled]);

    const handleSwitch=()=>{
        if(phNo===""){
            errorHandler({message:"Please Provide Your Mobile Number"});
        }
        else{
            setEnabled(!enabled)
        }
    }

    const handleShowCustomizesWid=()=>{
        setShowCustomizeWid(!showCustomizeWid);
    }
    const updatePhno=()=>{
        if(phNo===""){
            errorHandler({message:"Please Provide Your Mobile Number"});
        }
        else if(isCreated){
            updateLead(whatApp.id,{settings: {
                mobile_number:phNo}
            },true);
        }
    }

    return (
        <div className="cart-settings uk-width-5-6@m lead-capture whatsapp-lead">
            <div className="uk-padding uk-border-rounded box-shadow-medium uk-background-default">
                <h3>WhatsApp Widget</h3>
                <div className="uk-width-3-5@m uk-text-light uk-text-secondary uk-margin-bottom">
                All the details of the product will be sent to the users Watsapp contact when they click the widget icon on the product page and this information will be available on the chat box as they message you.
                </div>
                <div className="uk-grid  uk-child-width-1-3@s uk-flex-start uk-text-left">
                    <div>
                    <div className="uk-card uk-card-default">
                            <button className="uk-button uk-button-default uk-button-small">
                                <a>
                                    <img className="whatsapp-img" src={Img.whatsapp}/>
                                </a>
                                Chat Now</button>
                    </div>
                    </div>
                </div>
                <div className="uk-margin uk-width-1-4@s ">
                    <PhoneInput
                        placeholder="Enter phone number"
                        value={ phNo }
                        onChange={ phone =>phone?setPhNo(phone):setPhNo("")}
                        onBlur={updatePhno}
                        />
                </div>

                <div className="uk-grid uk-grid-small uk-flex-start uk-text-left uk-margin-top ">
                    <div className="uk-width-1-5@m e-switch-container">
                        <Switch height={22}  checked={enabled} onColor="#ef7f30" uncheckedIcon={false} checkedIcon={false} onChange={handleSwitch}/>
                        <span>Turned ON</span>
                    </div>
                    <div className="uk-width-4-5@m btn-right-text">
                        Want to Make Changes?<span onClick={handleShowCustomizesWid}>Customize Widget</span>
                    </div>
                </div>
                {showCustomizeWid&&isCreated&&<CustomizeWidget widget={whatApp}
                    updateLead={updateLead}
                    widgetName="whatsapp"

                />}
            </div>
        </div>
    )
}

const mapStateToProps = ({Shop}) => ({
    apps:Shop.apps
});

const mapDispatchToProps={
    createLeadWidget:createLeadWidget,
    fetchAllLeads,
    updateLead
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WhatsAppLead);
