import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function FormInput(props) {
    const {onChange, caption, className, ...rest} = props;

    const ref = React.createRef();

    return (
        <div
            className={classNames("e-input", "form-group", {
                focused: props.value
            })}
        >
            <label onClick={() => ref && ref.current.focus()} className="form-label">
                {caption}
            </label>
                    <input
                        className={classNames("form-input", className)}
                        ref={ref}
                        type="text"
                        onChange={e => props.onChange(e.target.value)}
                        name={props.id}
                        required
                        {...rest}
                    />
        </div>
    );
}

FormInput.propTypes = {
    caption: PropTypes.string.isRequired
};

export default FormInput;
