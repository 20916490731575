import React from "react";
import Img from "../../constants/Img";


function Coming(props) {

    return (
        <div className="uk-width-1-1@m">
            <div className="shop-container uk-text-center uk-align-center uk-margin-top uk-width-3-5">
                <img alt="" width="250" src={Img.starter}/>
                <h2>Coming soon...</h2>
                <p>
                    Page you are requested is under construction, buckle your belt.
                </p>
            </div>
        </div>
    );
}

export default Coming;