import React,{useState,useEffect} from "react";
import Switch from "react-switch";
import Img from "../../constants/Img";
import { setHours, setMinutes} from "date-fns/esm";
//import {template} from '../../constants/template'

function FollowUp({followup,updateFollowup,fetchEmailTemplateInfo,createNewFollowup}) {
    const template="";
    let minutes=followup.time_delay;
    let days=minutes%1440?Math.floor(minutes/1440):0;
        minutes=minutes%1440;
    let hours=minutes%60?Math.floor(minutes/60):0;
        minutes=minutes%60;
        const [d,setD]=useState(days);
        const [h,setH]=useState(hours);
        const [m,setM]=useState(minutes);
        const [status,setStatus]=useState(followup.status);
        const [didMount,setDidMount]=useState(false);

        // useEffect(()=>{
        //     if(followup.template!==null)
        //         fetchEmailTemplateInfo(followup.template.id);
        // },[])
        const handleChange=(e)=>{
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value)) {
            if(e.target.name==="days"){
                if(e.target.value<0)
                    setD(0);
                else
                    setD(e.target.value);
                if(d===0&&h===0&&m<15)
                    setM(15);
            }

            if(e.target.name==="hours"){
                if(e.target.value<0)
                    setH(0);
                else if(e.target.value>23)
                    setH(23);
                else
                    setH(e.target.value);
                if(d===0&&h===0&&m<15)
                    setM(15);
            }

            if(e.target.name==="minutes"){
                if(e.target.value<0)
                    setM(0);
                else if(e.target.value>59)
                    setM(59);
                else
                    setM(e.target.value);
            }
        }
        }
        const handleswitch=()=>{
            setStatus(status===2?1:2);
        }
        const handleUpdate=()=>{
            if(d==0)
                setD(0);
            if(h==0)
                setH(0);
            if(d==0&&h==0&&m<15)
                setM(15);
            let td=d*1440+h*60+parseInt(m);
            updateFollowup(followup.id,{
                ...followup,
                time_delay:td>=15?td:15,
                status:status
            })
        }
        const handleCreate=()=>{
            createNewFollowup({
                ...followup,
                time_delay:d*1440+h*60+parseInt(m),
                status:status
            })
        }
        useEffect(() => {
            if(didMount)
                isNaN(followup.id)?handleCreate():handleUpdate();
            else
                setDidMount(true);
          }, [status]);

        const timeConvert=(time)=>{
            let str="";
            let d=Math.floor(time/24/60);
            let h=Math.floor(time/60%24);
            let m=Math.floor(time%60);
            if(d)
                str+=d+" Days ";
            if(h)
                str+=h+" Hours ";
            if(m)
                str+=m+" Minutes ";
             return str;
          }

        return (
        <li>
            <div className="firstfollowuptab follow-up">
                <div className="e-switch-container">
                    <Switch height={22} onChange={handleswitch} checked={followup.status===2?true:false} onColor="#ef7f30" checkedIcon={false} uncheckedIcon={false} checked={status===2?true:false} />
                    <span>Turned ON</span>
                </div>
                <div className="uk-flex uk-margin-small-top uk-margin-small-bottom">
                    <p className="uk-margin-remove-bottom uk-text-meta">Triggering in</p>
                    <div className="uk-text-meta trigger-time">&nbsp;{timeConvert(followup['time_delay'])}</div>
                </div>
                <div className="uk-margin-remove-top uk-margin-medium-bottom uk-flex uk-flex-middle">
                    <img src={Img.datetime} alt=""/>
                    {/* <span>&nbsp;{followup['time_delay']} mins</span> */}
                    <div className="follow-up-input day">
                        <input name="days" placeholder="00" type="text" value={d} onChange={handleChange}
                            onBlur={handleUpdate}
                        />
                        <span>D</span>
                    </div>
                    <div className="follow-up-input hours">
                        <input name="hours" placeholder="00" type="text" value={h} onChange={handleChange}
                            onBlur={handleUpdate}
                        />
                        <span>H</span>
                    </div>
                    <div className="follow-up-input minutes">
                        <input name="minutes" placeholder="00" type="text" value={m} onChange={handleChange}
                            onBlur={handleUpdate}
                        />
                        <span>M</span>
                    </div>
                </div>
                <p className="uk-margin-remove-bottom uk-text-meta">Mail template</p>
                {/* <p className="uk-margin-remove-top uk-margin-medium-bottom">Not chosen yet <span
                    className="change_template uk-margin-large-left"><a
                    href="##">Change template</a></span></p> */}
                <div className="uk-card uk-card-default">
                    {
                        !followup.template&&
                        <div
                        className="template_choose_box uk-text-center uk-padding uk-margin-remove-bottom">
                        {/* <p className="uk-text-small">Templates aren't chosen yet</p> */}
                        <button className="choosenowtemplate" type="button" name="button">
                            Activated
                        </button>
                    </div>
                    }
                    
                    <div className="remove_editcontent">
                        <p>
                            <a className="x_remove" href="##">x Remove</a>
                            <a className="uk-margin-medium-left" href="##">
                                <img className="img_baseline" src={Img.edit} alt=""/>
                                Edit Content
                            </a>
                        </p>
                        <div id="white_overlay"></div>
                        <div contentEditable='true'dangerouslySetInnerHTML={{ __html: template }}>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}


export default FollowUp;
