import React, { useState, useEffect } from "react";
import Img from "../../constants/Img";
import { isEmpty as Empty, Alert } from "../../utils";
import { find, get } from "lodash";
import ReactTooltip from 'react-tooltip'

function MailDetails({setAllMails, createAllEmails, mails}) {

    const fromObject = find(mails, {category: "from"});
    const toObject = find(mails, {category: "reply_to"});

    const [fromName, setFromName] = useState("");
    const [replyName, setReplyName] = useState("");
    const [fromAddress, setFromAddress] = useState("operator@ecart.chat");
    const [replyAddress, setReplyAddress] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingReplyName, setIsEditingReplyName] = useState(false);
    const [isEditingReplyEmail, setIsEditingReplyEmail] = useState(false);
    let nameInput = React.createRef();
    let replyInput = React.createRef();
    let replyEmailInput = React.createRef();

    useEffect(()=>{
        replyInput.current.focus();
    },[isEditingReplyName])
    useEffect(()=>{
        nameInput.current.focus();
    },[isEditing])
    useEffect(()=>{
        replyEmailInput.current.focus();
    },[isEditingReplyEmail])

    useEffect(()=>{
        setFromName(get(fromObject,"name",""));
        setReplyName(get(toObject,"name",""));
        setReplyAddress(get(toObject,"address",""));
    },[mails]);

    const onUpdatePress = () => {

        if (!Empty([fromName, replyName, replyAddress])) {
            setIsEditing(false);
            setIsEditingReplyName(false);
            setIsEditingReplyEmail(false);
            const from  = {
                "category": "from",
                "name": fromName,
                "address": fromAddress
            };

            const to = {
                "category": "reply_to",
                "name": replyName,
                "address": replyAddress
            };

           (fromObject ? ()=> setAllMails(fromObject['id'], from) : ()=>createAllEmails(from))() ;
           (toObject ? ()=> setAllMails(toObject['id'], to) : ()=>createAllEmails(to))();
        }else{
            Alert("Please fill all the details","error");
        }
    };

    return (
        <div className="mailing_details uk-margin-right uk-margin-small-top">
            <ReactTooltip />
            <div className="uk-flex uk-flex-middle uk-flex-between email-heading">
                <h4>Mailing details</h4>
                <div>
                <span>{isEditing||isEditingReplyName||isEditingReplyEmail?"save ":""}</span><img onClick={onUpdatePress} alt=""src={Img.tick} width={15} data-tip="Save Details"/>
                </div>
            </div>

            <img src={Img.addressarrow} alt=""/>
            <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-meta">From name</p>
            <div>
                <p className={isEditing?"input-label uk-hidden":"input-label"}
                        onClick={()=>{setIsEditing(true);nameInput.current.focus()}}
                    >
                    {fromName?fromName:"From Name"}
                </p>
                <input
                    ref={nameInput}
                    onChange={(e) => setFromName(e.target.value)}
                    className={!isEditing?"e-mail-details-input uk-hidden":"e-mail-details-input"}
                    placeholder="Enter name"
                    onBlur={()=>setIsEditing(false)}
                    value={fromName}/>
            </div>
            <p className="uk-margin-remove-bottom uk-text-meta">From address</p>
            <input
                disabled={true}
                className="e-mail-details-input"
                type="text"
                value={fromAddress}/>
            <p><img src={Img.addressreply} alt=""/></p>
            <p className="uk-margin-small-top uk-margin-remove-bottom uk-text-meta">Reply name</p>
            {/* reply Name Label with edit option*/}
            <p className={isEditingReplyName?"input-label uk-hidden":"input-label"}
                        onClick={()=>{setIsEditingReplyName(true);replyInput.current.focus()}}
                    >
                    {replyName?replyName:"Reply Name"}
                </p>
            <input
                ref={replyInput}
                onChange={(e) => setReplyName(e.target.value)}
                className={!isEditingReplyName?"e-mail-details-input uk-hidden":"e-mail-details-input"}
                type="text"
                placeholder="Enter name"
                onBlur={()=>setIsEditingReplyName(false)}
                value={replyName}/>
            <p className="uk-margin-remove-bottom uk-text-meta">Reply to address</p>
            {/* reply Email Label with edit option*/}
            <p className={isEditingReplyEmail?"input-label uk-hidden":"input-label"}
                        onClick={()=>{setIsEditingReplyEmail(true);replyInput.current.focus()}}
                    >
                    {replyAddress?replyAddress:"ReplyAddress"}
                </p>
            <input
                ref={replyEmailInput}
                onChange={(e) => setReplyAddress(e.target.value)}
                className={!isEditingReplyEmail?"e-mail-details-input uk-hidden":"e-mail-details-input"}
                type="text"
                onBlur={()=>setIsEditingReplyEmail(false)}
                placeholder="Enter mail address"
                value={replyAddress}/>
        </div>
    )
}

export default MailDetails;