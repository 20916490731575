import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function FormInputWithEdit(props) {
    const {onChange, caption, className,value,onBlur, ...rest} = props;
    const [isEditing, setIsEditing] = useState(false);
    const ref = React.createRef();
    useEffect(()=>{
        ref.current.focus();
    },[isEditing])

    const keypressHandler=(event)=>{
        if (event.key === "Enter")
            ref.current.blur();
    }
    return (
        <div
            className={classNames("e-input", "form-group", {
                focused: props.value
            })}
        >
            <label onClick={() => ref && ref.current.focus()} className="form-label">
                {caption}
            </label>
            {/* Label with Edit Option */}
            <p onClick={() => {ref && ref.current.focus();setIsEditing(true)}} 
            className={isEditing?"input-label uk-hidden":"input-label"}
            style={{paddingTop:"12px"}}
            >
                {value}
            </p>
                    <input
                        className={classNames("form-input",!isEditing?"uk-hidden":"", className)}
                        ref={ref}
                        type="text"
                        onKeyPress={event => keypressHandler(event)}
                        onBlur={()=>{onBlur();setIsEditing(false)}}
                        value={value}
                        onChange={e => {props.onChange(e.target.value)}}
                        name={props.id}
                        required
                        {...rest}
                    />
        </div>
    );
}

FormInputWithEdit.propTypes = {
    caption: PropTypes.string.isRequired
};

export default FormInputWithEdit;
