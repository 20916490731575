import React from "react";
import { Route, Redirect } from "react-router-dom";


function HomeRoute({component:Component,user, ...rest}){
    switch (rest['path']){
        case "/home/noshop":
        case "/home/details":
            return (<Route component={Component} {...rest}/>);
        default:
            return(
                <Route {...rest}
                    render={(props)=>(user.shops.length ? <Component {...props}/> : <Redirect to="/home/noshop"/>)} />);
    }
}

export default HomeRoute;