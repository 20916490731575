import Img from "./Img";

export const options = {
    title: "",
    legend: {
        enabled: false
    },
    yAxis: {
        gridLineWidth: 1,
        title: {
            text: "Count(in numbers)"
        }
    },
    xAxis: {
        gridLineWidth: 1
    },
    series: [
        {
            color: "#ee5e0f",
            data: [1, 2, 3, 4, 5]
        }
    ]
};

export const radio_scale = new Map([
    ["Today", "today"],
    ["This week", "week"],
    ["This month", "month"],
]);

export const tabs = new Map([
    ["Cart Count", "count"],
    ["Cart Amount", "amount"]
]);

export const cart_cards = [
    {
        img: Img.abandonvalue,
        text: "Abandoned Cart Value",
        graphKey: "recent_amount",
        show_currency:true
    },
    {
        img: Img.abandoncount,
        text: "Abandon Cart Count",
        graphKey: "recent_count",
        show_currency:false
    },
    {
        img: Img.activevalue,
        text: "Active Cart Value",
        graphKey: "active_amount",
        show_currency:true
    },
    {
        img: Img.activecount,
        text: "Active Cart Count",
        graphKey: "active_count",
        show_currency:false
    }
];


export const linkArray = {
    home: [
        {
            text: "Summary",
            path: "/home",

        },
        {
            text: "Carts List",
            path: "/home/carts",
        },
        {
            text: "Product List",
            path: "/home/cart"
        },
        {
            text: "Abandoned Products",
            path: "/home/products",
        },
    ],
    noshop: [
        {
            text: "Initiating",
            path: "/home/noshop",

        },
        {
            text: "Add store",
            path: "details",
        },
    ],
    settings: [
        {
            text: "Shop settings",
            path: "/home/settings",

        },
        {
            text: "Switch Shops",
            path: "/home/shops",

        }
    ],
    lead:[
        {
            text:"Email Widget",
            path:"/home/lead"
        },
        {
            text:"WhatsApp widget",
            path:"/home/lead/whatsapp"
        }
    ],
    subscribers:[
        {
            text:"Wishlist",
            path:"/home/subscribers"
        }
    ]
};

