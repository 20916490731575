import React from "react";
import {Link} from 'react-router-dom'
import CartInfo from "./Cart";
import CartNav from "./Nav";
import { get } from "lodash";
import Img from "../../constants/Img";
import {updateShopId} from '../../utils'

function Sidebar({ routes,user,onSettingsClick,location,setCurrentShopId,storeUpdateShopDetails }) {
    const {shops}=user;
    let shop={};
    if(user.current_shop_id===null){
        shop=shops.filter(s=>s.default===true)[0];
        if(!shop)
            shop = get(user,'shops[0]');
        setCurrentShopId(shop.shop_id);
        updateShopId(shop.shop_id);
        storeUpdateShopDetails();
    }
    else{
        shop=shops.filter(s=>s.shop_id===user.current_shop_id)[0];
    }
    const shopImage = get(shop,'logo_url',Img.userbig);
    return (shop!==undefined&&
        <div className="uk-width-1-6@m cart-details">
            <div className="image-container">
                <div className="round-logo" style={{
                    backgroundImage: `url(${shopImage?shopImage:Img.store})`
                }}>
                    <Link to="/home/shops" className="plus">+</Link>
                </div>
            </div>
            <div className="uk-grid uk-grid-small cart-container">
                <div className="uk-width-1-1">
                    <CartInfo shop={shop} onSettingsClick={onSettingsClick}/>
                </div>
                <div className="uk-width-1-1">
                    <CartNav routes={routes} location={location}/>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
