import React,{useEffect} from "react";
import { map, isArray, get, isEmpty } from "lodash";
import { connect } from "react-redux";
import Sidebar from "../../components/Sidebar";
import { routes } from "../../constants/Global";
import Navbar from "../../components/Navbar";
import HomeRoute from "../../components/PrivateRoute/Home";
import { userSave, uploadAvatar, showPasswordPopUp,setCurrentShopId,clearCurrentShopId,storeUpdateShopDetails,fetchAllUserDatails } from "../../store/actions";
import { getRoutes } from "../../utils";

const noSidebarRoutes = ["/home/noshop", "/home/campaigns"]

function Home({history, saveUser, uploadAvatar, user, location, showPasswordPopUp,setCurrentShopId ,clearCurrentShopId,fetchAllUserDatails, storeUpdateShopDetails }) {

    const settingsClick = React.useCallback(() => {
        if (isArray(user.shops) && !isEmpty(user.shops)) {
            history.replace("/home/settings");
        }
    }, [user,history]);

    useEffect(()=>{
        fetchAllUserDatails();
    },[]);

    const checkSidebar = ()=> noSidebarRoutes.indexOf(location.pathname)  <  0 && get(user,"shops[0]",false)

    return (
        <React.Fragment>
            <Navbar uploadAvatar={uploadAvatar} saveUser={saveUser} showPasswordPopUp={showPasswordPopUp} user={user} location={location} clearCurrentShopId={clearCurrentShopId}/>
            <section className="content uk-margin-medium-top uk-margin-small-bottom uk-container">
                <div className="uk-grid uk-grid-medium">
                    {
                        checkSidebar() &&
                        <Sidebar
                            user={user}
                            onSettingsClick={settingsClick}
                            routes={getRoutes(location.pathname)}
                            location={location}
                            setCurrentShopId={setCurrentShopId}
                            storeUpdateShopDetails={storeUpdateShopDetails}
                            />
                    }

                    {map(routes, route => (
                        <HomeRoute user={user} key={route.path} {...route} />
                    ))}
                </div>
            </section>
        </React.Fragment>
    );
}

const mapStateToProps = ({Shop}) => ({
    user: Shop.user,
});

const mapDispatchToProps = {
    saveUser: userSave,
    uploadAvatar: uploadAvatar,
    showPasswordPopUp:showPasswordPopUp,
    setCurrentShopId:setCurrentShopId,
    clearCurrentShopId,
    fetchAllUserDatails,
    storeUpdateShopDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
