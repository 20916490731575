import React, { useState, useCallback, useEffect } from "react";
import { map, get, isEmpty } from "lodash";
import FormInputWithEdit from "../../components/FormInputWithEdit";
import { connect } from "react-redux";
import Img from "../../constants/Img";
import { uploadShopImage, getShopInfo, setShopInfo } from "../../store/actions"
import { caller } from "../../utils";
import { errorHandler, successHandler } from "../../utils";

const Settings = ({Shop, uploadImage, getShopInfo, setShopInfo}) => {

    const [storeImage, setStoreImage] = useState(Img.store);
    const [shopName, setShopName] = useState(Shop.name);
    const [shopTimeout, setShopTimeout] = useState(0);

    let inputElement = React.createRef();

    const handleImageChange = (e) => {
        const types=['image/gif','image/png','image/jpeg','image/bmp','image/webp'];
        if (e.target.files.length) {
            const file = new FormData();
            file.append("logo", e.target.files[0]);
            if(types.includes(e.target.files[0].type))
            {
                uploadImage(file);
            }
            else{
                errorHandler({message:"Invalid File Type"});
            }
        }
    };


    const onClickUploader = useCallback(() => {
        inputElement.current.click();
    }, [inputElement]);


    const onNameFocusOut = useCallback(() => {
        setShopInfo({
            "name": shopName
        });
    }, [shopName,setShopInfo]);


    // useEffect(() => {
    //     caller(getShopInfo);
    // }, [getShopInfo]);


    useEffect(() => {
        const curShopId=get(Shop, "user.current_shop_id");
        const shop = get(Shop, "user.shops");
        if (!isEmpty(shop)) {
            const curShop=shop.filter(s=>s.shop_id===curShopId)[0];
            setStoreImage(get(curShop, "logo_url", Img.store));
            setShopName(get(curShop, "name", ""));
            setShopTimeout(get(curShop, "cart_timeout", 0))
        }
    }, [Shop]);

    useEffect(() => {
        if (shopTimeout )
            setShopInfo({"cart_timeout": shopTimeout})
    }, [shopTimeout,setShopInfo]);


    return (
        <div className="cart-settings uk-width-5-6@m">
            <div className="uk-padding uk-border-rounded box-shadow-medium uk-background-default">
                <h3>Shop settings</h3>
                <div className="uk-width-1-2">You can check the basic details related to your store and can make the
                    changes here
                </div>
                <div className="uk-width-3-4 settings-item">
                    <span className="shade-text">Store image</span>
                    <div className="uk-grid uk-grid-collapse">
                        <div className="uk-width-1-5">
                            <div
                                onClick={onClickUploader}
                                className="store-logo">
                                <img src={storeImage} width={40} alt=""/>
                                <img className="store-upload" src={Img.camerablue} alt=""/>
                                <input
                                    className="img-uploader"
                                    type="file"
                                    ref={inputElement}
                                    onChange={handleImageChange}
                                    accept="image/*"/>
                            </div>
                        </div>
                        <div className="uk-width-2-5">
                            <div className="form">
                                <FormInputWithEdit
                                    className="input-width"
                                    value={shopName}
                                    onBlur={onNameFocusOut}
                                    caption="Shop name"
                                    onChange={setShopName}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-width-3-4@m settings-item">
                    <span className="shade-text">Cart abandoned timeout (in minutes)</span>
                    <div className="uk-grid uk-width-3-4">
                        <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid graph-scale">
                            {ShopTimeout(Shop.timeout, shopTimeout, setShopTimeout)}
                        </div>
                    </div>
                </div>

                <div className="uk-width-3-4 settings-item">
                    <span className="shade-text">Time zone</span>
                    <div className="shade-text">{Shop.timezone}</div>
                </div>
                <div className="uk-width-3-4 settings-item">
                    <span className="shade-text">Currency</span>
                    <div className="shade-text">{Shop.currency}</div>
                </div>
            </div>
        </div>);
};


function ShopTimeout(timeoutArray, activeTimeout, updateTimeout) {
    return (
        <React.Fragment>
            {map(
                timeoutArray, (timeout) => {
                    return (<label key={timeout}>
                        <input
                            className="uk-radio"
                            type="radio"
                            name="scale"
                            onClick={() => updateTimeout(timeout)}
                            checked={activeTimeout === timeout}
                        />
                        {timeout} mins
                    </label>)
                })}
            <label>
                <input
                    className="uk-radio"
                    type="radio"
                    name="scale"
                    onClick={() => {
                        if (timeoutArray.indexOf(activeTimeout) !== -1) {
                            updateTimeout(15);
                        }
                    }}
                    checked={timeoutArray.indexOf(activeTimeout) === -1}
                />
                Custom
            </label>
            <div className="custom-timeout">
                <input
                    disabled={timeoutArray.indexOf(activeTimeout) !== -1}
                    placeholder="000"
                    onChange={(e) => updateTimeout(e.target.value)}
                    value={activeTimeout} type="text"/>
                <span className="shade-text">mins</span>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = ({Shop}) => ({
    Shop
});


const mapDispatchToProps = {
    uploadImage: uploadShopImage,
    getShopInfo: getShopInfo,
    setShopInfo: setShopInfo
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings);
