import * as types from "../constants";

export const getDashboard = (scale = "week") => ({
    type: types.GET_DASHBOARD_DATA,
    payload: scale
});

export const getCart = (scale = "week", updateRequest = false) => ({
    type: types.GET_ABANDON_CART_DATA,
    payload: scale,
    updateRequest
});
export const getCartsItem = (id) => ({
    type: types.GET_CARTS_ITEM,
    payload: id,
  })
export const getProducts = (scale = "month", updateRequest = false) => ({
    type: types.GET_ABANDON_PRODUCTS_DATA,
    payload: scale,
    updateRequest
});
export const toggleCartListPopup = () => ({
    type: types.TOGGLE_CART_LIST_POPUP,
    payload: [],
  });
export const signupUser = ({payload, history}) => ({
    type: types.SIGNUP_USER_REQUEST,
    payload,
    history
});
export const verifyUser = ({payload, history}) => ({
    type: types.VERIFY_USER_REQUEST,
    payload,
    history
});

export const signInUser = ({payload, history}) => ({
    type: types.SIGNIN_USER_REQUEST,
    payload,
    history
});

export const getSession = (history) => ({
    type: types.FIND_ACTIVE_SESSION,
    history
});

export const reloadSession = (history) => ({
    type: types.RELOAD_USER,
    history
});

export const userSave = (payload) => ({
    type: types.SAVE_USER,
    payload,
});

export const uploadAvatar = (payload) => ({
    type: types.UPLOAD_AVATAR,
    payload,
});

export const uploadShopImage = (payload) => ({
    type: types.UPLOAD_SHOP_IMAGE,
    payload,
});

export const getShopInfo = () => ({
    type: types.SHOP_GET_DETAILS,
});


export const setShopInfo = (payload) => ({
    type: types.SHOP_UPDATE_DETAILS,
    payload
});


export const getFollowUps = () => ({
    type: types.MAIL_GET_INFO,
});

export const getAllMails = () => ({
    type: types.MAIL_GET_ALL_MAILS,
});

export const setAllEmails = (id,payload) => ({
    type: types.MAIL_SET_ALL_MAILS,
    payload:{
        id,
        data: payload
    }
});

export const createAllEmails = (payload) => ({
    type: types.MAIL_CREATE_ALL_MAILS,
    payload
});

//Create New Followup
export const createNewFollowup=(follwup)=>({
    type:types.CREATE_EMAIL_FOLLOWUP,
    payload:follwup
})

//Update Followup
export const updateFollowup=(id,payload)=>({
    type:types.UPDATE_FOLLOWUP,
    payload:{
        id:id,
        data:payload
    }
})

export const fetchEmailTemplateInfo=(id)=>({
    type:types.FETCH_EMAIL_TEMPLATE_INFO,
    payload:id
})

export const storeUpdateFollowup=(payload)=>({
    type:types.STORE_UPDATE_FOLLOWUP,
    payload
})

//Add newly created folloup to store
export const storeupdateNewFollowup=(payload)=>({
    types:types.STORE_ADD_NEW_FOLLOWUP,
    payload
})

export const fetchAllUserDatails = () => ({
    type: types.FETCH_ALL_USER_DETAILS
});

export const storeFetchAllUserDetails=(payload)=>({
    type:types.STORE_FETCH_ALL_USER_DETAILS,
    payload
})

export const makeShopDefault=(id)=>({
    type:types.MAKE_SHOP_DEFAULT,
    payload:id
});

export const updateDefaultShop=(shop)=>({
    type:types.UPDATE_DEFAULT_SHOP,
    payload:shop
})

//Action to Switch Shop
 export const switchShop=(shop_id)=>({
     type:types.SWITCH_SHOP,
     payload:shop_id
 })

//Creating New Lead
export const createLeadWidget=(payload)=>({
    type:types.CREATE_LEAD_WIDGET,
    payload
})

export const storeAddNewWidget=(payload)=>({
    type:types.STORE_ADD_NEW_WIDGET,
    payload
})

//Fetch All Leads
export const fetchAllLeads=()=>({
    type:types.FETCH_ALL_LEADS,
})

export const storeupdateFetchedLeads=(payload)=>({
    type:types.STORE_UPDATE_FETCHED_LEADS,
    payload
})

export const updateLead=(id,data,showMsg=false)=>({
    type:types.UPDATE_LEAD,
    payload:{
        id,
        data,
        showMsg
    }
})
export const storeupdateUpdatedLeads=(payload)=>({
    type:types.STORE_UPDATE_UPDATED_LEADS,
    payload
})

export const showPasswordPopUp=()=>({
    type:types.SHOW_PWD_POPUP
})

export const hidePasswordPopUp=()=>({
    type:types.HIDE_PWD_POPUP
})

//Acton to fetch all subscribers
export const fetchSubscribers=(scale = "week", updateRequest = false)=>({
    type:types.FETCH_SUBSCRIBERS,
    payload:scale,
    updateRequest
})
export const clearSubscribers=()=>({
    type:types.CLEAR_SUBSCRIBERS
});
export const showInnerPageLoader=()=>({
    type:types.SHOW_INNER_PAGE_LOADER
})
export const hideInnerPageLoader=()=>({
    type:types.HIDE_INNER_PAGE_LOADER
});
export const setLoadingFinished=(status=false)=>({
    type:types.LOADING_COMPLETED,
    payload:status
})

export const getCartsList = (scale = "week", updateRequest = false) => ({
    type: types.CARTS_LIST,
    payload: scale,
    updateRequest
  });
  export const showPopup = ({ payload, history }) => ({
     type: types.SHOW_POPUP,
     payload,
     history
    })
//to get currently clicked cart items
export const getActiveCart=(id)=>({
    type: types.GET_ACTIVE_CART,
    payload:id
    });
//Set current shop id during shop switch
export const setCurrentShopId=(shopId)=>({
    type:types.SET_CURRENT_SHOP_ID,
    payload:shopId
});

export const storeUpdateShopDetails=()=>({
    type:types.STORE_UPDATE_SHOP_DETAILS
});
export const clearCurrentShopId=()=>({
    type:types.CLEAR_CURRENT_SHOP_ID
});

export const emailSubscribersList=(payload)=>({
    type:types.EMAIL_SUBSCRIBER_LIST,
    payload
})

export const removeShop=(id)=>({
    type:types.REMOVE_SHOP,
    payload:id
})

export const setQueryString=(data)=>({
    type:types.SET_QUERY_STRING,
    payload:data
})
export const resetQueryString=()=>({
    type:types.RESET_QUERY_STRING
})