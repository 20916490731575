import React,{ useEffect } from "react";
import { connect } from "react-redux";
import Img from "../../constants/Img";
import { reloadSession } from "../../store/actions";

function Reloader({reloadSession,history}){

    useEffect(()=>{
        reloadSession(history);
    },[reloadSession,history]);

    return (
        <div className="uk-flex uk-flex-middle uk-flex-center uk-height-1-1 uk-width-1-1">
            <img src={Img.preloader} alt=""/>
        </div>
    )
}

const mapDispatchToProps = {
    reloadSession
}



export default connect(null,mapDispatchToProps)(Reloader);