//ser Reducer 
import * as types from "../constants";
const initialState={
    name: "",
    avatar_url:"",
    auth: null,
    shops: [],
    email:null,
    shops:[]
}
const reducer = (state = initialState, action) => {
    const {type,payload}=action;
    switch (type) {
        case types.STORE_FETCH_ALL_USER_DETAILS:
            return {...state,shops:payload.shops,email:payload.email,name:payload.name,avatar_url:payload.avatar_url,auth:payload.auth}
        case types.UPDATE_DEFAULT_SHOP:
            return {...state,shops:state.shops.map(shop=>shop.id===payload.id?payload:{...shop,default:false})
            }
        default:
            return state;
    }
};
export default reducer;