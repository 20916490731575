import React from "react";

function CartCard(props) {
  return (
    <div>
      <div className="uk-card uk-card-default">
        <img src={props.img} alt="" />
        <h3 className="uk-card-title">{props.text}</h3>
  <p>{props.show_currency&& <span dangerouslySetInnerHTML={{__html: props.currency}}></span>} { props.value}</p>
      </div>
    </div>
  );
}

export default CartCard;
