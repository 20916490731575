import React from "react";
import { Provider } from "react-redux";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "./html/src/scss/index.scss";
import store from "./store";
import Router from "./Router";
import queryString from  'query-string';

UIkit.use(Icons);


function App({history}) {
    return (
        <Provider store={store}>
            <Router location={history.location}/>
        </Provider>
    );
}

export default App;
