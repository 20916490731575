import React,{ useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Home from "./views/Home";
import Auth from "./views/Auth";
import Reloader from "./views/Reload";
import { LocalStore } from "./store";
import Notification from "./components/Notification";
import PrivateRoute from "./components/PrivateRoute/Auth";
import { getSession,clearCurrentShopId,setQueryString} from "./store/actions";
import queryString from 'query-string';

function AppRouter({ findActiveSession,user,history, clearCurrentShopId,location,setQueryString }) {

    useEffect(() => {
        findActiveSession(history);
    }, [findActiveSession,history]);
    useEffect(()=>{
        clearCurrentShopId();
        const parsed = queryString.parse(location.search);
        setQueryString(parsed);
    },[])

    return (
            <Router>
                {user.auth && <Redirect to="/home"/>}
                <Notification setNofification={(notification)=>LocalStore.alert = notification}/>
                <Route exact path="/"  render={() => <Redirect to="/auth"/>}/>
                <Route exact path="/reload" component={Reloader}/>
                <Route path="/auth" component={Auth}/>
                <PrivateRoute path="/home" user={user} component={Home}/>
            </Router>
    );
}



const mapStateToProps = ({Shop:{user}})=>({
    user
});

const mapDispatchToProps = {
    findActiveSession: getSession,
    clearCurrentShopId,
    setQueryString
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);

