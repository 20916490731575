import React,{useEffect,useCallback} from 'react';
import {connect,useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import Img from "../../constants/Img";
import * as types from '../../store/constants'
import {updateShopId,caller} from '../../utils'
import { LocalStore } from "../../store";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {fetchAllUserDatails,makeShopDefault,getShopInfo,setCurrentShopId,clearSubscribers,removeShop,storeUpdateShopDetails } from '../../store/actions'
const SwitchShop=({shops,curShopId,fetchAllUserDatails,makeShopDefault,getShopInfo,setCurrentShopId, clearSubscribers, removeShop,storeUpdateShopDetails })=>{

    useEffect(()=>{
        //fetchAllUserDatails();
        return () => {
            clearSubscribers();
          }
    },[])

    const handleShopSwitch=(shop_id)=>{
        updateShopId(shop_id);
        setCurrentShopId(shop_id);
        storeUpdateShopDetails();
        //caller(getShopInfo)
    }

    const handleRemoveShop=(shop)=>{
        if(shop.shop_id!==curShopId){
            removeShop(shop.id);
        }
        else
            LocalStore.alert("Switch shop and try again","error");
    }

    const openConfirm=(shop)=>{
        confirmAlert({
            title: 'Confirm to Remove',
            message: 'Are you sure to remove the shop?',
            buttons: [
              {
                label: 'Yes',
                onClick: () =>{
                    handleRemoveShop(shop)
                }
              },
              {
                label: 'No',
                onClick: () =>{}
              }
            ]
          });
    }

    return (
        <div className="switch-shops uk-width-5-6@m">
            <div className="uk-padding uk-border-rounded box-shadow-medium uk-background-default">
                <h3>Switch Shops</h3>
                <div className="uk-grid">
                <div className="uk-width-1-2 uk-margin-bottom">You can change your active shop ay time from here. <br/>As well as the shp status.</div>
                    <div className="uk-width-1-2 uk-text-right"> 
                        <Link to="/home/noshop" className="add-shop-btn" >Add New Shop</Link>
                    </div>
                </div>
                {/* shop cards */}
                {
                    shops.map(shop=>shop.default?<div className="shop-cards">
                    <div className="uk-grid uk-grid-small uk-child-width-1-3@l uk-child-width-1-2@m uk-flex-start">
                        <div>
                            <div className="uk-card uk-card-default shop-card">
                                <div className="uk-inline switch-block">
                                    <label>
                                    <input class="uk-radio" name="shop" type="radio" onChange={()=>handleShopSwitch(shop.shop_id)} checked={shop.shop_id===curShopId?true:false}/>
                                    Switch Shop
                                    </label>
                                </div>
                                {/* <div class="uk-inline more-option">
                                        <span uk-icon="icon: more-vertical;boundary: .uk-card" type="button"></span>
                                        <div uk-dropdown="mode: click">
                                        <ul class="uk-list">
                                            <li>
                                                <a>Remove Shop</a>
                                            </li>
                                        </ul>
                                        </div>
                                    </div> */}
                                <div className="uk-inline default-msg"><img src={Img.tick} onClick={()=>handleShopSwitch(shop.shop_id)}/>
                                    Default
                                </div>
                                <div className="uk-grid" style={{alignItems:"center"}}>
                                    <div className="uk-width-1-4">
                                        <img className="shop-logo" src={shop.logo_url?shop.logo_url:Img.logo}/>
                                    </div>
                                    <div className="uk-width-3-4">
                                        <h3 className="uk-card-title">{shop.name}</h3>
                                        <div className="uk-width-1-1 uk-text-small">{shop.domain}</div>
                                        <div>status: <span className="uk-text-warning">Active</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>:""
            )
                }
                <div style={{padding:"25px 0"}}>Other Shops</div>
            {/* shop cards */}
            <div className="uk-grid uk-child-width-1-3@l uk-child-width-1-2@m  uk-flex-start uk-text-left">
             {
                    shops.map(shop=>!shop.default?<div className="shop-cards">
                    <div className="uk-margin-medium-bottom">
                        <div>
                            <div className="uk-card uk-card-default shop-card">
                                <div className="uk-inline switch-block">
                                    <label>
                                    <input class="uk-radio" type="radio" name="shop" onChange={()=>handleShopSwitch(shop.shop_id)} checked={shop.shop_id===curShopId?true:false}/>
                                    Switch Shop
                                    </label>
                                </div>
                                <div class="uk-inline more-option">
                                        <span uk-icon="icon: more-vertical;boundary: .uk-card" type="button"></span>
                                        <div uk-dropdown="mode: click">
                                        <ul class="uk-list">
                                            <li>
                                                <a onClick={()=>makeShopDefault(shop.id)}>Make Default</a>
                                            </li>
                                            <li>
                                                <a onClick={()=>openConfirm(shop)}>Remove Shop</a>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                <div className="uk-grid" style={{alignItems:"center"}}>
                                    <div className="uk-width-1-4">
                                        <img className="shop-logo" src={shop.logo_url?shop.logo_url:Img.logo}/>
                                    </div>
                                    <div className="uk-width-3-4">
                                        <h3 className="uk-card-title"dangerouslySetInnerHTML={{__html:shop.name}} ></h3>
                                        <div className="uk-width-1-1 uk-text-small">{shop.domain}</div>
                                        <div>status: <span className="uk-text-warning">Active</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>:""
            )
                }
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = ({Shop}) => ({
    shops:Shop.user.shops,
    curShopId:Shop.user.current_shop_id
});
const mapDispatchToProps={
        fetchAllUserDatails:fetchAllUserDatails,
        makeShopDefault:makeShopDefault,
        getShopInfo:getShopInfo,
        setCurrentShopId:setCurrentShopId,
        clearSubscribers,
        removeShop,
        storeUpdateShopDetails
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SwitchShop);
