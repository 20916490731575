import React,{useState,useEffect,useRef} from 'react';
import ColorPick from '../../components/LeadCapture/ColorPick';
import ShopPreview from '../../components/LeadCapture/ShopPreview'
import Switch from 'react-switch'

function CustomizeWidget({widget,updateLead,widgetName}){
    const [titleColor,setTitleColor]=useState(widget.settings.title_colour?widget.settings.title_colour:"#0d0d88");
    const [buttonColor,setButtonColor]=useState(widget.settings.title_colour?widget.settings.btn_colour:"#ef7f30");
    const [title,setTitle]=useState(widget.settings.title?widget.settings.title:"Wish to Buy Later?");
    const [btnText1,setBtnText1]=useState(widget.settings.btn_text_1?widget.settings.btn_text_1:"Remind Me");
    const [btnText2,setBtnText2]=useState(widget.settings.btn_text_2?widget.settings.btn_text_2:"Receive");
    const [btnText,setBtnText]=useState(widget.settings.btn_text?widget.settings.btn_text:"Chat Now");
    const [tooltip,setTooltip]=useState(widget.settings.tooltip?widget.settings.tooltip:"Not ready to buy now ? Don't worry ! We'll remind you later with an exciting offer. Just set a reminder now. to Buy Later?");
    const [defaultStyle,setDefaultStyle]=useState(widget.settings.default_style);
    const mounted = useRef();
    const handleChangeColor=(name,color)=>{
        if(name==="titleColor")
            setTitleColor(color);
        else if(name==="buttonColor")
            setButtonColor(color);
    }

    const handleDefaultStyle=()=>{
        setDefaultStyle(!defaultStyle);
    }

    const handleUpdate=()=>{
        if(widgetName==="email")
        updateLead(widget.id,{
            settings:{
                title:title,
                title_colour:titleColor,
                btn_colour:buttonColor,
                tooltip:tooltip,
                btn_text_1:btnText1,
                btn_text_2:btnText2,
                default_style:defaultStyle
            }
        },true)
        else if(widgetName==="whatsapp"){
            updateLead(widget.id,{
                settings:{
                    btn_text:btnText,
                    title_colour:titleColor,
                }
            },true)
        }
    }
    useEffect(()=>{
        if (!mounted.current) {
            mounted.current = true;
        } else {
            handleUpdate();
          }
    },[defaultStyle])

    const handleChange=(e)=>{
        const {value}=e.target;
        switch(e.target.name){
            case "title":
                setTitle(value);
                break;
            case "btnText1":
                setBtnText1(value);
                break;
            case "btnText2":
                setBtnText2(value);
                break;
            case "tooltip":
                setTooltip(value);
                break;
            case "btnText":
                setBtnText(value);
                break;
            default:
                break;
        }
    }

    return (
        <div className="uk-grid uk-grid-small">
        <div className="customize-widget uk-width-3-5@m uk-width-1-2@s">
            <h5>Customize widget</h5>
            {widgetName==="email"&&
            <div className="e-switch-container uk-margin-top">
                <Switch height={22} onChange={()=>{}} checked={defaultStyle} onColor="#ef7f30" uncheckedIcon={false} checkedIcon={false} onChange={handleDefaultStyle}/>
                <span>Use Default Theme Styles</span>
            </div>
            }
            <form className="uk-form-stacked" onSubmit={(e)=>e.preventDefault()}>
                {
                    widgetName==="whatsapp"&&
                    <div className="uk-grid">
                    <div className="uk-margin">
                        <label className="uk-form-label">Button Text</label>
                        <input className="uk-input uk-form-width-medium uk-form-small" type="text" value={btnText} name="btnText" onChange={handleChange}/>
                    </div>
                    <ColorPick label="Text Color" name="titleColor" color={titleColor}
                        handleChangeColor={handleChangeColor}
                    />
                </div>
                }
                {
                widgetName==="email"&&
                <React.Fragment>
                <div className="uk-grid">
                    <div className="uk-margin">
                        <label className="uk-form-label">Title Content</label>
                        <input className="uk-input uk-form-width-medium uk-form-small" type="text" value={title} name="title" onChange={handleChange}/>
                    </div>
                    {!defaultStyle&&<ColorPick label="Title Color" name="titleColor" color={titleColor}
                        handleChangeColor={handleChangeColor}
                    />
                    }
                </div>
                <div className="uk-grid uk-margin-remove-top ">
                    <div className="uk-margin">
                        <label className="uk-form-label">Button1 Text</label>
                        <input className="uk-input uk-form-width-medium uk-form-small" type="text" name="btnText1" value={btnText1} onChange={handleChange}/>
                    </div>
                    {!defaultStyle&&<ColorPick label="Button Color" name="buttonColor" color={buttonColor}
                        handleChangeColor={handleChangeColor}
                    />
                    }
                </div>
                <div>
                    <label className="uk-form-label">Button2 Text</label>
                    <input className="uk-input uk-form-width-medium uk-form-small" type="text" name="btnText2" value={btnText2} onChange={handleChange}/>
                </div>
                <div className="uk-margin">
                    <label className="uk-form-label">Tooltip Text</label>
                    <input className="uk-input uk-form-width-medium uk-form-small" type="text" name="tooltip" value={tooltip} onChange={handleChange}/>
                </div>
                </React.Fragment>
                }
                <button className="uk-button save-btn uk-button-small" onClick={handleUpdate}>
                    Save
                </button>
            </form>
        </div>
        <div className="uk-width-2-5@m uk-width-1-2@s">
            <h5 className="uk-text-center">Live Preview</h5>
            <ShopPreview title={title} titleColor={titleColor} buttonColor={buttonColor} tooltip={tooltip} btnText1={btnText1} btnText2={btnText2} widgetName={widgetName} btnText={btnText}/>
        </div>
        </div>
    )
}

export default CustomizeWidget
