import React, { useState, useEffect, useCallback } from "react";
import Img from "../../constants/Img";
import PropTypes from "prop-types";
import classNames from "classnames";

/*
    types: ["success","error"]
*/

function Notification({setNofification, timeout}) {

    const [opacity, setOpacity] = useState(0);
    const [visibility, setVisibility] = useState("hidden");
    const [message, setMessage] = useState("");
    const [type, setType] = useState("success");

    const hideImmediate = useCallback(() => {
        setOpacity(0);
        setTimeout(() => {
            setVisibility("hidden")
        }, 500);
    }, []);

    const hideIt = useCallback(() => {
        setTimeout(() => {
            hideImmediate();
        }, timeout);
    }, []);

    const showIt = useCallback(() => {
        setVisibility("visible");
        setOpacity(1);
    }, []);


    const updateMessage = (message, type = "success") => {
        if (!opacity) {
            setMessage(message);
            setType(type);
            showIt();
            hideIt();
        }
    };

    useEffect(() => {
        if (typeof setNofification === "function")
            setNofification(updateMessage);
    }, []);


    return (
        <div style={{opacity, visibility}} className={classNames("global-notification")}>
            <img className="success-image" src={Img[type]} width="20" alt=""/>
            <span className="message">{message}</span>
            <span className="close-button" uk-icon="icon: close; ratio: .8" onClick={hideImmediate}/>
        </div>
    );

}

Notification.propTypes = {
    setNofification: PropTypes.func,
    timeout: PropTypes.number
};

Notification.defaultProps = {
    visible: false,
    timeout: 3000
};

export default Notification;