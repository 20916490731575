import React, { useState } from "react";
import { get, keys } from "lodash";
import { Link } from "react-router-dom";
import Img from "../../constants/Img";
import UserProfile from "./UserProfile";
import PasswordWidget from "../PasswordWidget";
import classNames from "classnames";

const links = {
    "Overview": ["/home", "/home/","/home/carts","/home/carts","/home/products"],
    "Shop": ["/home/settings","/home/settings/", "/home/shops","/home/shops/"],
    // "Mail": ["/home/mail", "/home/mail/"],
    "Lead Capture": ["/home/lead", "/home/lead/","/home/lead/whatsapp"],
    "Subscribers": ["/home/subscribers", "/home/subscribers/"],

};

function Navbar({user, saveUser, uploadAvatar, location, showPasswordPopUp, clearCurrentShopId }) {

    const [visibleProfile, setVisibleProfile] = useState(false);
    const userImage = get(user, "avatar_url", Img.userbig) || Img.userbig;

    return (
        <React.Fragment>
        <div
            className="uk-box-shadow-small uk-background-default"
            uk-sticky="media: @m"
        >
            <nav className="uk-navbar-container uk-container uk-navbar">
                <div className="uk-navbar-left">
                    <Link className="uk-navbar-item uk-logo" to="/home">
                        <img
                            src={Img.logo}
                            className="uk-margin-small-right uk-img"
                            alt=""
                        />
                    </Link>
                    <ul className="uk-navbar-nav">
                        {
                            keys(links).map((key) => {
                                return (
                                    <li key={key} className={
                                        classNames({"uk-active": links[key].find((val) => location.pathname === val)})
                                    }>
                                        <Link to={links[key][0]}>{key}</Link>
                                    </li>)
                            })
                        }
                    </ul>
                </div>
                <div className="uk-navbar-right e-nav-right">
                    <div className="e-user-field">
                        <img width={34} src={userImage} className="uk-img" alt=""/>
                        <a href="##" uk-icon="chevron-down" onClick={(e) => {
                            e.preventDefault();
                            setVisibleProfile(true)
                        }}>
                            <span className="user-name">{user.name}</span>
                        </a>
                    </div>
                    {visibleProfile &&
                    <UserProfile
                        user={user}
                        uploadAvatar={uploadAvatar}
                        saveUser={saveUser}
                        showPasswordPopUp={showPasswordPopUp}
                        setVisibleProfile={setVisibleProfile}
                        clearCurrentShopId={clearCurrentShopId}
                        />}
                </div>
            </nav>
        </div>
        <PasswordWidget/>
        </React.Fragment>
    );
}

export default Navbar;
