import React from "react";
import Img from "../../constants/Img";
import { get } from "lodash";

function CartInfo({shop,onSettingsClick}) {

    const domain = get(shop,"domain","#");

    const gotoStore = (e)=>{
        e.preventDefault();
        window.open(domain);
    };

    return (
        <div className="border-round uk-text-center uk-card uk-card-default cart-info">
            <h3 className="uk-card-title" dangerouslySetInnerHTML={{__html:shop.name}}></h3>
            <p className="sub-title">{shop.platform}</p>
            <button onClick={onSettingsClick}
                    className="e-settings-button uk-button uk-box-shadow-medium uk-button-primary uk-border-pill">
                <img src={Img.settings} alt=""/>
                <span>
                    Settings
                </span>
            </button>
            <div className="card-details">
                <img alt="products" src={Img.more}/>
                <a href={domain} onClick={gotoStore} className="uk-link-muted">Goto my store</a>
            </div>
        </div>
    );
}


CartInfo.defaultProps = {
    onSettingsClick: () => {
    }
};

export default CartInfo;
