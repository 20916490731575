import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import CartListItem from '../CartListItem';
import { get, map } from "lodash";
import { declare } from "../../utils";
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group';

function CartListPopup({onOutsideClick, cartsItem}) {
  const items = get(cartsItem, "cart_items");
  const customer = get(cartsItem,"customer");
  return (
    <div id="popup" uk-modal className="uk-modal cartlist">
      <OutsideClickHandler onOutsideClick={onOutsideClick} >
        <div className="uk-margin-vertical uk-modal-dialog  uk-modal-body">
          <div className="uk-flex orderIdDetails">
            <div className="orderId">
              <p className="uk-margin-remove uk-text-muted uk-text-small">
                Order ID 
              </p>
              <p className="uk-text-bold"> {'100 ' + cartsItem.id}</p>
            </div>
            <div className="uk-flex-4 nameAndMail">
              <p className="uk-margin-remove uk-text-top uk-text-bold">
              {declare(get(customer, "email"), "Email Not Available")}
              </p>
              <p className="uk-margin-remove uk-text-muted">{declare(get(customer, "name"), "Visitor")}</p>
            </div>
            <div className="uk-flex-1 uk-text-center">
              <p className="uk-margin-remove uk-text-muted uk-text-small">
                Count
              </p>
              <p className="uk-text-bold">{cartsItem.total_quantity} item(s)</p>
            </div>
            <div className="totalAmount uk-text-right">
              <p className="uk-margin-remove uk-text-muted uk-text-small">
                Total amount
              </p>
              <p className="uk-text-bold">{'$ ' + cartsItem.total_amount + '.00'}</p>
            </div>
          </div>
          <p className="uk-text-muted uk-margin-left	">Item(s) in cart</p>
          <TransitionGroup className="todo-list">
          {map(items, item => (
              <CSSTransition
              key={item.id}
              timeout={500}
              classNames="trans-item"
              >
              <CartListItem  key={item.id} index={items.indexOf(item)} item={item}/>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </OutsideClickHandler>
    </div>
  );
}


export default CartListPopup;