import React, { useState, useCallback,useEffect } from "react";
import FormInput from "../FormInput";
import { resetPassword } from "../../store/api";
import { errorHandler, successHandler } from "../../utils";
import { connect } from 'react-redux';
import { hidePasswordPopUp } from '../../store/actions'
function PasswordWidget({ UI, hidePasswordPopUp }) {

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const resetFields = ()=>{
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
    }

    useEffect(()=>{
        if(UI.showPwdPopUp)
            document.body.style['overflow-y'] = 'hidden';
        else
            document.body.style['overflow-y'] = 'scroll';
    },[UI.showPwdPopUp])
    const resetPasswordButton =useCallback( (e)=> {
        resetPassword({
            current_password: currentPassword,
            password: newPassword,
            password_confirmation: confirmPassword

        }).then((data)=>{
            successHandler(data)
            resetFields();
            hidePasswordPopUp();
        }).catch((error)=>{
            errorHandler(error);
            resetFields();
        })
    },[confirmPassword,currentPassword,newPassword])

    return (
        <div id="password-overlay" class={UI.showPwdPopUp?"open":""}>
            <div className="e-update-password">
                <h2 className="uk-modal-title">Update password</h2>
                <div>
                    <span>
                        Change your password frequently for improving the security factor. You can use alphanumeric
                        passwords with special characters
                    </span>
                    <FormInput
                        type="password"
                        value={currentPassword}
                        onChange={setCurrentPassword}
                        caption="Current password"/>
                    <FormInput
                        type="password"
                        value={newPassword}
                        onChange={setNewPassword}
                        caption="New password"/>
                    <FormInput
                        type="password"
                        value={confirmPassword}
                        onChange={setConfirmPassword}
                        caption="Confirm new password"/>
                </div>
                <div className="uk-text-left e-button-area">
                    <button onClick={resetPasswordButton} className="e-button e-button-small" type="button">Update</button>
                    <span className="uk-modal-close e-cancel-button" type="button"
                    onClick={()=>hidePasswordPopUp()}
                    >Cancel</span>
                </div>
            </div>
        </div>
    )

}
const mapStateToProps = ({UI}) => ({
    UI
});

const mapDispatchToProps = {
    hidePasswordPopUp:hidePasswordPopUp
};

export default connect(mapStateToProps,mapDispatchToProps)(PasswordWidget);
