import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import FormInput from "../../components/FormInput";
import Img from "../../constants/Img";
import * as Actions from "../../store/actions";
import Notification from "../../components/Notification";
import * as Api from "../../store/api";

function Reset({location, history}) {

    const [otp, setOtp] = useState("");
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");


    const resetPassword = useCallback(() => {

        Api.resetPassword({
            otp_code: otp,
            email: get(location, "state.email", ""),
            otp_token: get(location, "state.token", ""),
            password: password,
            password_confirmation: confirm
        }).then((resp) => {
            history.replace({
                pathname: "/auth"
            })
        }).catch((err) => {
            console.log(err);
        });


    }, [otp, password, confirm]);

    return (
        <div id="registration" className="flex_wrapper">
            <div className="create_account">
                <div className="box1">
                    <img src={Img.regimage} alt=""/>
                    <h1>Reset Password!</h1>
                    <h2>
                        Create a alphanumeric password with special characters for making it extra strong
                    </h2>
                </div>
                <Notification message="A secure OTP has been sent to your given email id" visible={true}/>
            </div>
            <div className="reg_form reset-form">
                <div className="box2">
                    <form action="" className="form">
                        <FormInput
                            caption="Enter OTP"
                            id="last"
                            required
                            value={otp}
                            onChange={setOtp}
                        />
                        <FormInput
                            caption="New password"
                            id="first"
                            required
                            value={password}
                            type="password"
                            onChange={setPassword}
                        />
                        <FormInput
                            caption="Confirm new password"
                            id="first"
                            required
                            value={confirm}
                            type="password"
                            onChange={setConfirm}
                        />
                    </form>

                    <div className="">
                        <button
                            id="registernow"
                            type="button"
                            name="button"
                            onClick={resetPassword}
                        >
                            Reset password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    verifyUser: Actions.verifyUser
};

export default connect(
    null,
    mapDispatchToProps
)(Reset);
