import React from "react";
import { get } from "lodash";
import { declare } from "../../utils";
import dateFormat from "dateformat";
import Img from "../../constants/Img";

function SubscriberItem(props) {
  const { subscriber } = props;
  return (
    <li className="uk-flex uk-flex-middle cart-item subscriber-item uk-border-rounded box-shadow-medium">
      <div className="details-header uk-flex uk-flex-middle">
        <img src={subscriber.details.thumbnail?subscriber.details.thumbnail:Img.NoImage} className="uk-img item-image uk-margin-small-right" alt="Product Image" />
          <div className="cart-item-name">{subscriber.item_name}</div>
        </div>
      <div>
        <div className="details-header">
          <div className="buyer-name">
            <img src={Img.usersmall} width="14" className="uk-img" alt="" />
            {subscriber.details.user_name?subscriber.details.user_name:"Guest"}
          </div>
          <div className="buyer-email">
          {subscriber.email}
          </div>
        </div>
      </div>
      <div>
        <img src={Img.datetime} className="uk-img" alt="" />
        <span>{dateFormat(subscriber.details.created_at, "dd mmm yyyy,hh:MMtt")}</span>
      </div>
    </li>
  );
}

export default SubscriberItem;