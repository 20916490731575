import React, { useState,useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { map, get, keys, values, isEmpty } from "lodash";
import classNames from "classnames";
import CartCard from "../../components/CartCard";
import * as Actions from "../../store/actions";
import { options, radio_scale, tabs, cart_cards } from "../../constants/Home";
import Img from "../../constants/Img";
import { caller,formatDate } from "../../utils";
import "react-datepicker/dist/react-datepicker.css";

function updateOptions(graph) {
    const op = {...options};
    op.xAxis.categories = keys(graph);
    op.series[0].data = values(graph);
    return op;
}

const LandingHome = props => {
    const {dash} = props;
    const {Shop}=props;


    const [activeGraph, setActiveGraph] = useState("Cart Count");
    const [graphKey, setGraphKey] = useState("This week");
    const [dateStart,setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());



    const onRadioChange = (ip)=>{
        setGraphKey(ip);
        const scale = ip === "custom" ?
        {
            "start_date": formatDate(dateStart),
            "end_date": formatDate(dateEnd),
        } :
        {scale: radio_scale.get(ip)}
        caller(props.getDashboard,scale);
    };


    useEffect(()=>{
        const scale = {
            "start_date": formatDate(dateStart),
            "end_date": formatDate(dateEnd),
        };
        caller(props.getDashboard,scale);
    },[props.getDashboard,dateStart,dateEnd]);



    return (
        <div className="cart-summary uk-width-5-6@m">
            <div className="uk-padding uk-border-rounded box-shadow-medium uk-background-default">
                <div className="header-bar uk-navbar">
                    <div className="uk-navbar-left report-header">Cart Summary</div>
                    <div className="uk-navbar-right">
                        <span>Get report on </span>
                        <span className="mail">
                          <img alt="" src={Img.mail}/>
                          Mail
                        </span>
                        {/* <span className="print">
                          <img alt="" src={Img.print}/>
                          Print
                        </span> */}
                    </div>
                </div>
                <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid graph-scale">
                    {map([...radio_scale.keys()], ip => (
                        <label key={ip}>
                            <input
                                className="uk-radio"
                                type="radio"
                                name="scale"
                                onChange={()=>onRadioChange(ip)}
                                checked={ip === graphKey}
                            />
                            {ip}
                        </label>
                    ))}
                    <div className={
                        classNames("uk-flex", graphKey === "custom" && "uk-border-rounded box-shadow-medium custom-picker")} >
                        <label>
                            <input
                                className="uk-radio"
                                type="radio"
                                name="scale"
                                onChange={() => onRadioChange("custom")}
                                checked={"custom" === graphKey}/>
                                Custom period
                        </label>
                        <div style={{ display: graphKey === "custom"  ? "block" : "none"}}>
                            <DatePicker selected={dateStart} onChange={setDateStart} />
                            <DatePicker selected={dateEnd} minDate={dateStart} onChange={setDateEnd} />
                        </div>
                    </div>

                </div>

                <div className="cart-cards">
                    <div className="uk-grid uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center">
                        {map(cart_cards, (card, index) => (
                            <CartCard
                                key={index}
                                img={card.img}
                                text={card.text}
                                value={get(dash, card.graphKey)}
                                currency={Shop.currency_symbol}
                                show_currency={card.show_currency}
                            />
                        ))}
                    </div>
                </div>

                <ul className="uk-tab" uk-tab="true">
                    {map([...tabs.keys()], (item, index) => (
                        <li
                            key={index}
                            onClick={() => setActiveGraph(item)}
                            className={classNames({"uk-active": item === activeGraph})}
                        >
                            <a href="##">{item}</a>
                        </li>
                    ))}
                </ul>
                {!isEmpty(props.graph[tabs.get(activeGraph)]) && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={updateOptions(props.graph[tabs.get(activeGraph)])}
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({Home, Shop}) => ({
    dash: Home.dashData,
    graph: Home.dashGraph,
    Shop
});

const mapDispatchToProps = {
    getDashboard: Actions.getDashboard
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingHome);
