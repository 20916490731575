import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import Home from "./reducers";
import Shop from "./reducers/shop";
import User from "./reducers/user"
import rootSaga from "./sagas";
import UI from './reducers/ui'
const composeEnhancers = process.env.NODE_ENV !== 'production' &&window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: compose;

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    combineReducers({
            Home,
            Shop,
            UI
        }
    ),
    composeEnhancers(
    applyMiddleware(sagaMiddleware)
));

sagaMiddleware.run(rootSaga);

export const LocalStore = {
    alert: ()=>{}
}

export default store;
