const IMG = {
    logo: require("../images/logo.png"),
    mail: require("../images/mail.png"),
    print: require("../images/print.png"),
    dropdown: require("../images/dropdown-grey.png"),
    more: require("../images/more-details.png"),
    usersmall: require("../images/user-details.png"),
    userbig: require("../images/user.png"),
    messenger: require("../images/messenger.png"),
    flag: require("../images/flag.png"),
    trash: require("../images/trash.png"),
    products: require("../images/products.png"),
    abandonvalue: require("../images/abandon-value.png"),
    abandoncount: require("../images/abandon.png"),
    activevalue: require("../images/active-value.png"),
    activecount: require("../images/active-count.png"),
    datetime: require("../images/date-time.png"),
    bgimage: require("../images/bg-shade.png"),
    regimage: require("../images/reg-image.png"),
    starter: require("../images/starter.png"),
    success: require("../images/success.png"),
    wf: require("../images/wf.png"),
    camera: require("../images/camera.png"),
    camerablue: require("../images/camera-blue.png"),
    tick: require("../images/tick.png"),
    cancel: require("../images/cancel.png"),
    edit: require("../images/edit_purple.png"),
    error: require("../images/error.png"),
    settings: require("../images/settings.png"),
    store: require("../images/store.png"),
    addressarrow: require("../images/address-arrow.png"),
    addressreply: require("../images/replay-address-arrow.png"),
    preloader: require("../images/preloader.gif"),
    productImage:require("../images/prd_image.png"),
    increment:require('../images/increment.png'),
    decrement:require('../images/decrement.png'),
    whatsapp:require('../images/whatsapp.png'),
    rightArrow: require("../images/right-arrow-grey.png"),
    cartLogo: require("../images/Cart-id-grey.png"),
    FlagAbandoned: require("../images/FlagAbandoned.png"),
    FlagRecovered: require("../images/FlagRecovered.png"),
    NoImage:require('../images/noImg.png')
};

export default IMG;
