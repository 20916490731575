import React,{ useEffect,useState,useCallback } from 'react'
import { fetchSubscribers,showInnerPageLoader,setLoadingFinished,emailSubscribersList } from "../../store/actions"
import { connect } from 'react-redux'
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import {Pagination} from '../../constants/Global'
import SubscriberItem from '../../components/Subscribers/SubscriberItem'
import {map} from "lodash";
import * as Actions from "../../store/actions";
import { radio_scale } from "../../constants/Home";
import Img from "../../constants/Img";
import { caller, formatDate, getCallerName } from "../../utils";
import classNames from  "classnames";
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
    CSSTransition,
    TransitionGroup,
  } from 'react-transition-group';

const Subscribers = ({innerPageLoader,loadingCompleted,subscribers,showInnerPageLoader,fetchSubscribers,setLoadingFinished,emailSubscribersList }) => {

    const [page,setPage]=useState(1);
    const [activeRadio, setActiveRadio] = useState("This week");
    const [query,SetQuery]=useState("");
    const [dateEnd, setDateEnd] = useState(new Date());
    const [dateStart,setDateStart] = useState(new Date());

    const handleRadioChange =  useCallback((update = false,ip = activeRadio) => {
        const scale = ip === "custom" ?
        {
            "start_date": formatDate(dateStart),
            "end_date": formatDate(dateEnd),
            "query": query
        } :
        {scale: radio_scale.get(ip),"query": query}
        caller(fetchSubscribers,scale,update);
      },[activeRadio,fetchSubscribers,dateEnd,dateStart,query]);

      const handleContainerOnBottom = useCallback(() => {
        if(!loadingCompleted){
            showInnerPageLoader();
            handleRadioChange(true);
        }
      },[handleRadioChange]);


    useEffect(()=>{
        handleRadioChange();
    },[handleRadioChange,fetchSubscribers,dateStart,dateEnd]);

    const openConfirm=()=>{
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to receive subscriberlist via email.',
            buttons: [
              {
                label: 'Yes',
                onClick: () =>{
                    let data = activeRadio === "custom" ?
                    {
                        "start_date": formatDate(dateStart),
                        "end_date": formatDate(dateEnd),
                        "query": query
                    } :
                    {scale: radio_scale.get(activeRadio),"query": query};
                    emailSubscribersList(data);
                }
              },
              {
                label: 'No',
                onClick: () =>{}
              }
            ]
          });
    }


    useBottomScrollListener(handleContainerOnBottom)
    return (
        <div className="uk-width-5-6@m subscribers">
            <div className="uk-padding uk-border-rounded box-shadow-medium uk-background-default">
                <div className="header-bar uk-navbar">
                        <div className="uk-navbar-left report-header"><h3>Subscribers</h3></div>
                        <div class="uk-navbar-right">
                            <span>Get report on </span>
                            <span class="mail" onClick={openConfirm}><img src={Img.mail} />Mail</span>
                        </div>
                </div>

                {/* Filtering */}
                <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid graph-scale">
                    {map([...radio_scale.keys()], radio => (
                        <label key={radio}>
                            <input
                                className="uk-radio"
                                type="radio"
                                name="scale"
                                onChange={() => setActiveRadio(radio)}
                                checked={radio === activeRadio}
                            />
                            {radio}
                        </label>
                    ))}
                    <div className={
                        classNames("uk-flex", activeRadio === "custom" && "uk-border-rounded box-shadow-medium custom-picker")} >
                        <label>
                            <input
                                className="uk-radio"
                                type="radio"
                                name="scale"
                                onChange={() => setActiveRadio("custom")}
                                checked={"custom" === activeRadio}/>
                                Custom period
                        </label>
                        <div style={{ display: activeRadio === "custom"  ? "block" : "none"}}>
                            <DatePicker selected={dateStart} onChange={setDateStart} />
                            <DatePicker selected={dateEnd} minDate={dateStart} onChange={setDateEnd} />
                        </div>
                    </div>
                </div>

                <div className="header-bar uk-navbar">
                    <div className="uk-navbar-left">
                        <div className="searchbar-container">
                            <form className="uk-search uk-search-default uk-width-1-1" onSubmit={(e)=>e.preventDefault()}>
                                <span className="uk-search-icon-flip" uk-search-icon=""/>
                                <input
                                    className="uk-search-input uk-border-pill"
                                    type="search"
                                    placeholder="Search items..."
                                    onChange={(e) => {
                                        e.preventDefault();
                                        SetQuery(e.target.value);
                                    }}
                                />
                            </form>
                        </div>
                    </div>

                    <div className="uk-navbar-right">
                        <div>
                            <span className="sort-by-cart-value">Sort by cart value</span>
                            <img src={Img.dropdown} className="uk-img item-image" alt=""/>
                        </div>
                    </div>
                </div>
                {/* Filtering End */}

            {
                subscribers.length>0 &&
                <li className="uk-flex uk-flex-middle cart-item subscriber-item uk-border-rounded uk-visible@s ">
                    <div className="details-header">
                        <b>Product Name</b>
                    </div>
                    <div className="details-header">
                        <b>User Details</b>
                    </div>
                    <div className="details-header">
                        <b>Date of Subscription</b>
                    </div>
                </li>
            }
                <TransitionGroup>
                {
                    subscribers.map(subscriber=>(
                    <CSSTransition
                        key={subscriber.id}
                        timeout={500}
                        classNames="trans-item"
                    >
                        <SubscriberItem subscriber={subscriber}/>
                    </CSSTransition>
                    ))
                }
                </TransitionGroup>
                <TransitionGroup>    
                        {!subscribers.length&&
                        <CSSTransition
                        timeout={500}
                        classNames="trans-item"
                    >
                        <div className="not-found-msg"><span uk-icon="warning"></span>
                        No Records Found...!</div>
                        </CSSTransition>
                        }
                    </TransitionGroup>
                        {(innerPageLoader && !loadingCompleted) && <div class="loading uk-text-center">Loading More...</div>}
                </div>
        </div>
    )
}
const mapStateToProps = ({Shop,UI}) => ({
    subscribers:Shop.subscribers,
    innerPageLoader:UI.showInnerPageLoader,
    loadingCompleted:UI.loadingCompleted
});
const mapDispatchToProps={
    fetchSubscribers,
    showInnerPageLoader,
    setLoadingFinished,
    emailSubscribersList
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Subscribers);
