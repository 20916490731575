import { put, takeLatest, call, select } from "redux-saga/effects";
import { get } from "lodash";
import * as types from "../constants";
import * as Api from "../api";
import { errorHandler } from "../../utils";
import { Pagination } from "../../constants/Global";


function* getDashboard(action) {
    try {
        let dash = yield call(Api.getDashboardData, action.payload);
        let graph = yield call(Api.getDashboardGraph, action.payload);
        dash = get(dash, "data.data");

        graph = {
            count: get(graph, "data.data.count_graph.graph_data"),
            amount: get(graph, "data.data.amount_graph.graph_data")
        };

        yield put({
            type: types.DASHBOARD_LOADED,
            payload: {dash, graph}
        });
    } catch (e) {
        errorHandler(e);
        const auth = get(e, "response.data.status", "failure");
        if (auth === "failure") yield put({type: types.INVALID_AUTH});
    }

}

function* getAbandonCartList(action) {
    try{
        const abandonCart = yield select((state)=>state.Home.abandonCart.amount_list);
        const len = abandonCart.length;


        if(get(action,"updateRequest",false) ){ //&& len % Pagination === 0
            const nextPage = Math.floor(len/ Pagination) + 1;
            let cart_list = yield call(Api.getAbandonCartList, action.payload, nextPage);
            cart_list = get(cart_list, "data.data");
            const amount_list = cart_list.amount_list;
            if(amount_list.length)
                if(amount_list[amount_list.length - 1]['id'] !== abandonCart[len -1]['id'] )
                    yield put({type: types.ABANDON_CART_UPDATED, payload: cart_list});
        }else{
            let cart_list = yield call(Api.getAbandonCartList, action.payload);
            if(cart_list.data.status==="success"){
                cart_list = get(cart_list, "data.data");
                yield put({type: types.ABANDON_CART_LOADED, payload: cart_list});
            }
        }

    }catch(e){
        errorHandler(e);
    }
}

function* getAbandonProducts(action) {
    try{

        const abandonProducts = yield select((state)=>state.Home.abandonProducts.amount_list);
        const len = abandonProducts.length;


        let graph = yield call(Api.getAbandonProductsGraph, action.payload);
        graph = {
            count_list: get(graph, "data.data.count_graph.graph_data"),
            amount_list: get(graph, "data.data.amount_graph.graph_data")
        };

        if(get(action,"updateRequest",false) ){
            const nextPage = Math.floor(len/ Pagination) + 1;
            let products = yield call(Api.getAbandonProducts, action.payload, nextPage);

            products = get(products, "data.data");
            const amount_list = products.amount_list;
            if(amount_list.length)
                if(amount_list[amount_list.length - 1]['id'] !== abandonProducts[len -1]['id'] )
                    yield put({type: types.ABANDON_PRODUCTS_UPDATED , payload: {products,graph}} );

        }else{
            let products = yield call(Api.getAbandonProducts, action.payload);
            products = get(products, "data.data");

            yield put({type: types.ABANDON_PRODUCTS_LOADED,payload: {products, graph}});
        }

    }catch(e){
        errorHandler(e);
    }
}
function* getCartList(action) {
    try {
      const cartList = yield select(state => state.Home.abandonCartsList);
      const len = cartList.length;
      if (get(action, "updateRequest", false)) {
        const nextPage = Math.floor(len / Pagination) + 1;
        let carts_list = yield call(Api.getCartList, action.payload, nextPage);
        carts_list = get(carts_list, "data.data");
        if(carts_list.length){
            if(carts_list[carts_list.length - 1]['id'] !== cartList[len - 1]['id'])
              yield put({ type: types.UPDATE_CARTS_LIST, payload: carts_list})
        }
      } else {
            let carts_list = yield call(Api.getCartList, action.payload);
            carts_list = get(carts_list, "data.data");
            yield put({ type: types.LOADED_CARTS_LIST, payload: carts_list });
        }
    }catch(e){
      errorHandler(e);
    }
  }

  // get data to active cart item

function* getAbandonCartItem(action) {
     try {
        let cartItems = yield call(Api.getActiveCart, action.payload );
        let cartItemData = get(cartItems, "data.0");
        yield put({ type: types.UPDATE_ACTIVE_CART, payload: cartItemData });
        }catch (e) {
            errorHandler(e);
        }
    }
export default function* Home() {
    yield takeLatest(types.GET_DASHBOARD_DATA, getDashboard);
    yield takeLatest(types.GET_ABANDON_CART_DATA, getAbandonCartList);
    yield takeLatest(types.GET_ABANDON_PRODUCTS_DATA, getAbandonProducts);
    yield takeLatest(types.CARTS_LIST, getCartList);
    yield takeLatest(types.GET_ACTIVE_CART, getAbandonCartItem)
}

