import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { get } from "lodash";
import FormInput from "../../components/FormInput";
import Img from "../../constants/Img";
import * as Actions from "../../store/actions";
import { resetEmail } from "../../store/api";

function Forgot({ history}) {

    const [email, setEmail] = useState("");
    const onReset = useCallback(async (e) => {

        try {
            const response = await resetEmail(email);
            if (get(response, "data.otp_token")) {
                history.replace({
                    pathname: "/auth/reset",
                    state: {
                        token: get(response, "data.otp_token"),
                        email: email
                    }
                })
            }
        } catch (e) {
            console.log("Error: ", e);
        }

    }, [email]);


    return (
        <div id="registration" className="flex_wrapper">
            <div className="create_account">
                <div className="box1">
                    <img src={Img.regimage} alt=""/>
                    <h1>Forgot details?</h1>
                    <h2>
                        Dont worry, you can reset your password by entering the OTP receiving on your registered mail id
                    </h2>
                </div>
            </div>
            <div className="reg_form forgot-email">
                <div className="box2">
                    <form action="" className="form">
                        <FormInput
                            value={email}
                            caption="Registerd email ID"
                            onChange={setEmail}
                            id="last"
                            required
                        />
                    </form>

                    <div className="">
                        <button
                            id="registernow"
                            type="button"
                            name="button"
                            onClick={onReset}
                        >
                            Submit
                        </button>
                        <Link href="#" className="remember-details" to="/auth">I remember the details</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    verifyUser: Actions.verifyUser
};

export default connect(
    null,
    mapDispatchToProps
)(Forgot);
