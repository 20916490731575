import React from "react";
import classNames from "classnames";
import { Route, Link } from "react-router-dom";
import Img from "../../constants/Img";
import RegisterPage from "./Register";
import LoginPage from "./Login";
import OTPPage from "./Verify";
import Forgot from "./Forgot";
import Reset from "./Reset";
import { getSession } from "../../store/actions";
import { connect } from "react-redux";


function Register({match, location: {pathname}}) {


    return (
        <React.Fragment>
            <div className="register">
                <nav className="nav">
                    <div className="flex_wrapper">
                        <a className="website_link" href="https://wordpress.org/plugins/recover-woocommerce-abandoned-cart" target="_blank">
                                <img src={Img.logo} alt=""/>
                        </a>
                        <input type="checkbox" name="" value=""/>
                        <svg viewBox="0 0 15 15">
                            <path d="M3.5,3 11.5,3 M3.5,7 11.5,7 M3.5,11 11.5,11"/>
                        </svg>
                        <ul>
                            <li className={classNames({
                                active_link: pathname === "/auth/register"
                            })}>
                                <Link to="/auth/register">Register for free!</Link>
                            </li>
                            <li
                                className={classNames({
                                    active_link: pathname === "/auth"
                                })}>
                                <Link to="/auth">Login</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
                <main>
                    <Route exact path={match.path} component={LoginPage}/>
                    <Route path={`${match.path}/register`} component={RegisterPage}/>
                    <Route path={`${match.path}/verify`} component={OTPPage}/>
                    <Route path={`${match.path}/forgot`} component={Forgot}/>
                    <Route path={`${match.path}/reset`} component={Reset}/>
                </main>
                <footer>
                    <div className="flex_wrapper">
                        <p>All rights reserved</p>
                        <ul>
                            <a href="#">
                                <li>Privacy Policy</li>
                            </a>
                            <a href="#">
                                <li>Terms of use</li>
                            </a>
                            <a href="#">
                                <li>Contact</li>
                            </a>
                        </ul>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = ({Shop}) => ({
    user: Shop.user
});

const mapDispatchToProps = {
    findActiveSession: getSession
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
