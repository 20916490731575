import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import FormInput from "../../components/FormInput";
import Img from "../../constants/Img";
import * as Actions from "../../store/actions";
import { isEmpty } from "../../utils";
import { get } from "lodash";
import * as Api from "../../store/api";


function Verify({verifyUser, history, location, resendOTP}) {

    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [token, setToken] = useState("");

    const onClickRegister = useCallback(() => {
        if (isEmpty([email, otp])) {
            alert('Please fill all the fields: ');
            return;
        }
        const payload = {
            email: email,
            otp_code: otp,
        };
        if (token) payload['otp_token'] = token;
        verifyUser({payload, history});
    }, [otp, email, token,history, verifyUser]);


    const onResendOtp = useCallback((e) => {
        e.preventDefault();
        Api.resendOTP(email).then((data) => {
            setToken(get(data, "data.otp_token", ""));
        });
    }, [email]);


    useEffect(() => {
        if (get(location, "state.email", "")) {
            setEmail(get(location, "state.email"));
            setToken(get(location, "state.token"));
        }
    }, [location]);

    return (
        <div id="registration" className="flex_wrapper">
            <div className="create_account">
                <div className="box1">
                    <img src={Img.regimage} alt=""/>
                    <h1>Create an account</h1>
                    <h2>
                        Just create a new account with your mail id for free, or login in
                        now, if you already have an account.
                    </h2>
                </div>
            </div>
            <div className="reg_form">
                <div className="box2">
                    <form action="" className="form">
                        <FormInput
                            value={email}
                            caption="Email ID"
                            onChange={setEmail}
                            id="last"
                            disabled
                            required
                        />
                        <FormInput
                            caption="6 digit OTP"
                            id="first"
                            value={otp}
                            onChange={setOtp}
                            required
                        />
                    </form>

                    <div className="">
                        <button
                            id="registernow"
                            type="button"
                            name="button"
                            onClick={onClickRegister}
                        >
                            Confirm Now
                        </button>
                        <a href="##" onClick={onResendOtp}>Resend OTP</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    verifyUser: Actions.verifyUser
};

export default connect(
    null,
    mapDispatchToProps
)(Verify);
