import { get } from "lodash";
import { setToken } from "../store/api";
import { Headers } from "../constants/Global";
import { linkArray } from "../constants/Home";
import { LocalStore } from "../store";
import { saveUserDetails } from "../store/sagas/Shop";

export function declare(x, val) {
    return x ? x : val;
}

export function isEmpty(arr) {
    for (let o of arr) {
        if (!o) return true
    }
}

export function setLoginInfo(user) {
    localStorage.setItem("auth", JSON.stringify(user));
}

export function getUserInfo() {
    return localStorage.getItem("auth");
}
//for updating shop id in shop switch
export function updateShopId(shop_id){
    let auth=JSON.parse(localStorage.getItem("auth"));
    let headers=auth.headers;
    let nHeader={...headers,["Shop-Id"]:shop_id}
    auth={...auth,headers:nHeader};
    setToken(nHeader);
    setLoginInfo(auth);
}

export function destroySession() {
    localStorage.removeItem("auth");
}

export function setSaveHeaders({headers, data}, save = true) {
    const nHeader = {};
    Headers.forEach((head) => {
        nHeader[head] = headers[head];
    });

    const shops = get(data, "data.shops", false);
    const defaultShop=shops.filter(shop=>shop.default===true);
    if (shops.length) nHeader['Shop-Id'] = defaultShop.length?defaultShop[0]['shop_id']:shops[0]['shop_id'];
    setToken(nHeader);
    if (save) setLoginInfo({headers: nHeader, user: data.data});
}

export function updateUserInfo(userInfo) {
    const user = JSON.parse(getUserInfo());
    user['user'] = {...user['user'], ...userInfo}
    setLoginInfo(user);
}


export function caller(api, ...params) {
    setTimeout(() => {
        api(...params);
    }, 0)
}

export function getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        console.log(reader.result);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}


export function getRoutes(location) {
    switch (location) {
        case "/home/noshop":
        case "/home/noshop/":
        case "/home/details":
        case "/home/details/":
            return linkArray["noshop"];
        case "/home/":
        case "/home":
            return linkArray["home"];
        case "/home/settings":
        case "/home/settings/":
        case "/home/shops":
        case "/home/shops/":
            return linkArray["settings"];
        case "/home/lead":
        case "/home/lead/whatsapp":
            return linkArray["lead"];
        case "/home/subscribers":
        case "/home/subscribers/":
                return linkArray["subscribers"];
        default:
            return linkArray["home"];
    }
}

export function Alert(message, type="success"){
    LocalStore.alert(message,type);
}

export function errorHandler(e){
    const message = get(e,"response.data.message",get(e,"message"));
    if(message) LocalStore.alert(message,"error");
}

export function successHandler(e){
    const message = get(e,"data.message",get(e,"message",""));
    if(message) LocalStore.alert(message,"success");
}

export function formatDate(d){
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function getCallerName(func)
{
  if (!func) return "anonymous";
  let caller = func.caller;
  if (!caller) return "anonymous";
  caller = caller.toString();
  if (!caller.trim().startsWith("function")) return "anonymous";
  return caller.substring(0, caller.indexOf("(")).replace("function","");
}