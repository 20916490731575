import React from "react";
import FormInput from "../../components/FormInput";
import { connect } from "react-redux";
import Img from "../../constants/Img";

const Details = ({Shop}) => {
    return (
        <div className="cart-settings uk-width-5-6@m">
            <div className="uk-padding uk-border-rounded box-shadow-medium uk-background-default">
                <h3>Adding store details</h3>
                <div className="uk-width-3-5">
                    Growing your Messenger list has never been easier. Our partner apps quickly and effortlessly subscribe users to your Messenger list.
                </div>
                <div className="uk-width-3-4 settings-item">
                    <div className="uk-card uk-card-default uk-card-small e-card border-round uk-width-1-2@m">
                        <span className="shade-text">Store URL</span>
                        <div>{Shop.url}</div>
                        <div className="tags">
                            <span className="e-tag">WooCommerce</span>
                        </div>
                    </div>
                </div>

                <div className="uk-width-3-4 settings-item">
                    <FormInput caption="Shop name" value={Shop.name} className="shop-name"/>
                </div>

                <div className="uk-width-3-4 settings-item">
                    <div className="uk-grid uk-grid-collapse">
                        <div className="add-logo">
                            <img src={Img.camera}/>
                        </div>
                        <div className="text-details">
                            <div className="header">Add logo</div>
                            <div className="sub"> Max size 1 Mb</div>
                        </div>
                    </div>
                </div>

                <div  className="uk-width-3-4 settings-item">
                    <button className="e-button" type="button" name="button">
                        Update details
                    </button>
                    <a href="#" className="abort">Cancel</a>
                </div>

            </div>
        </div>);
};


const mapStateToProps = ({Shop}) => ({
    Shop
});

export default connect(
    mapStateToProps,
    null
)(Details);
