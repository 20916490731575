function addTitle() {
    if (this.title) {
        this.title.destroy();
    }

    var r = this.renderer,
        x = this.series[0].center[0] + this.plotLeft,
        y = this.series[0].center[1] + this.plotTop;
    this.title = r
        .text(`${this.series[0].data.length} Item(s)`, 0, 0)
        .css({
            color: "#4572A7",
            fontSize: "16px"
        })
        .hide()
        .add();

    var bbox = this.title.getBBox();
    this.title
        .attr({
            x: x - bbox.width / 2,
            y: y
        })
        .show();
}

export const options = {
    chart: {
        type: "pie",
        styledMode: true,
        height: 250,
        events: {
            load: addTitle,
            redraw: addTitle
        }
    },
    plotOptions: {
        pie: {
            size: 150,
            allowPointSelect: true,
            cursor: "pointer",
            center: [100, 50]
        }
    },
    legend: {
        layout: "horizontal",
        useHTML: true,
        floating: true,
        width: 400,
        verticalAlign: "middle",
        labelFormatter: function () {
            return (
                "<div class='legend-name'><span>" +
                this.y +
                "</span>" +
                this.name +
                "</div>"
            );
        }
    },

    series: [
        {
            type: "pie",
            innerSize: "82%",
            size: "100%",
            showInLegend: true,
            dataLabels: {
                enabled: false
            },
            borderWidth: 0,
            data: [
                {
                    name: "Apparels",
                    y: 18,
                    color: "#35bd75"
                },
                {
                    name: "Mobile Phones",
                    y: 9,
                    color: "#fa9d39"
                },
                {
                    name: "Sun glasses",
                    y: 6,
                    color: "#fa9d39"
                },
                {
                    name: "Kitchen Accessories",
                    y: 4,
                    color: "#ed145b"
                },
                {
                    name: "Stationary",
                    y: 3,
                    color: "#ebebeb"
                },
                {
                    name: "Food Items",
                    y: 2,
                    color: "#00bff3"
                },
                {
                    name: "Electronics",
                    y: 1,
                    color: "#e06fed"
                },
                {
                    name: "Others",
                    y: 1,
                    color: "#f0d74d"
                }
            ]
        }
    ],
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 800
                },
                // Make the labels less space demanding on mobile
                chartOptions: {
                    legend: {
                        verticalAlign: "bottom",
                        layout: "horizontal",
                        enabled: false
                    }
                }
            }
        ]
    }
};

export const tabs = new Map([
    ["Abandoned Cart Amount", "amount_list"],
    ["Abandoned Product Count", "count_list"]
]);
