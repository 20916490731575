import { find } from "lodash";
import * as types from "../constants";

const initialState = {
    image: "",
    name: "Your Shop",
    timeout: [60, 120, 240],
    timezone: "",
    currency: "",
    currency_symbol:"",
    url: "",
    user: {
        name: "",
        auth: null,
        shops: [],
        current_shop_id:null
    },
    followups: [],
    mails: [],
    apps:[],
    subscribers:[]
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTH_USER_FOUND:
            return {...state, user: {...action.payload, auth: true,current_shop_id:state.user.current_shop_id}};
        case types.INVALID_AUTH:
            return {...state, user: {...state.user, auth: false}};
        case types.SHOP_UPDATED:
            return {...state, user: {...state.user, shops:state.user.shops.map(shop=>shop.shop_id===action.payload.shop_id?action.payload:shop)}};
        case types.MAIL_INFO_FETCHED:
            return {...state, followups: action.payload};
        case types.MAIL_ALL_MAILS_FETCHED:
            return {...state, mails: action.payload};
        case types.MAIL_ALL_MAILS_UPDATED:
            return {...state, mails: action.payload};
        case types.STORE_ADD_NEW_WIDGET:
            return {...state,apps:[...state.apps,action.payload]}
        case types.STORE_UPDATE_FETCHED_LEADS:
            return {...state,apps:action.payload}
        case types.STORE_UPDATE_UPDATED_LEADS:
            return {...state,apps:state.apps.map(app=>app.id===action.payload.id?action.payload:app)}
        case types.STORE_UPDATE_FOLLOWUP:
            return {...state,followups:state.followups.map(followup=>followup.id===action.payload.id?{template:followup.template,...action.payload}:followup)}
        case types.STORE_ADD_NEW_FOLLOWUP:
            return{
                ...state,followups:[...state.followups,action.payload]
            }
        case types.STORE_UPDATE_FETCH_TEMPLATE:
                return{
                    ...state,followups:state.followups.map(followup=>followup.id===action.payload.email_followup_id?{...followup,template:action.payload}:followup)
                }
        case types.STORE_ADD_FETCHED_SUBSCRIBERS:
                return{
                    ...state,subscribers:[...action.payload]
                }
        case types.STORE_UPDATE_FETCHED_SUBSCRIBERS:
            return{
                ...state,subscribers:[...state.subscribers,...action.payload]
            }
        case types.CLEAR_SUBSCRIBERS:
                return{
                    ...state,subscribers:[]
                }
        case types.SET_CURRENT_SHOP_ID:
            return{
                ...state,user:{...state.user,current_shop_id:action.payload}
            }
        case types.UPDATE_DEFAULT_SHOP:
                return {...state,user:{...state.user,shops:state.user.shops.map(shop=>shop.id===action.payload.id?action.payload:{...shop,default:false})
            }}
        case types.STORE_FETCH_ALL_USER_DETAILS:{
                return {...state,user:{...state.user,shops:action.payload.shops}}
        }
        case types.STORE_UPDATE_SHOP_DETAILS:{
            const curShop=state.user.shops.filter(shop=>shop.shop_id===state.user.current_shop_id)[0];
            return {...state,currency:curShop.currency,currency_symbol:curShop.currency_symbol, timezone:curShop.time_zone,url:curShop.domain,image:curShop.logo_url,name:curShop.name}
        }
        case types.CLEAR_CURRENT_SHOP_ID:
            return{
                ...state,user:{...state.user,current_shop_id:null}
            }
        case types.STORE_UPDATE_REMOVE_SHOP:
            return {
                ...state,user:{...state.user,shops:state.user.shops.filter(shop=>shop.id!==action.payload)}
            }
        default:
            return state;
    }
};
export default reducer;
