import { put, call, takeLatest, select, delay } from "redux-saga/effects";
import { get, findIndex } from "lodash";
import * as types from "../constants";
import { Pagination } from "../../constants/Global";
import {
    getShopDetails,
    updateShopDetails,
    saveUser,
    uploadAvatar,
    getAllMails,
    uploadShopImage,
    getShopMailSettings,
	createNewMails,
	updateMails,
    setToken,
    createLeadWidget,
    fetchAllLeads,
    updateLead,
    updateFollowup,
    fetchEmailTemplateInfo,
    createNewFollowup,
    fetchAllUserDetails,
    makeShopDefault,
    fetchSubscribers,
    emailSubscribersList,
    removeShop
} from "../api";
import { getUserInfo, updateUserInfo, errorHandler, successHandler } from "../../utils";


export function* getShopInfo() {

    try{
        const details = yield call(getShopDetails);
        const shop = get(details, "data.data");
        const user = JSON.parse(getUserInfo())['user'];
        user['shops'] = [shop];
        updateUserInfo(user);
        // yield put({
        //     type: types.SHOP_UPDATED,
        //     payload: shop
        // });
    }catch(e){
        errorHandler(e);
    }


}

export function* setShopInfo(action) {

    try{
        const details = yield call(updateShopDetails, action.payload);
        const shop = get(details, "data.data");
        const user = JSON.parse(getUserInfo())['user'];
        user['shops'] = [shop];
        updateUserInfo(user);
        yield put({
            type: types.SHOP_UPDATED,
            payload: shop
        });
    }catch(e){
        errorHandler(e)
    }
}

export function* findSession(action) {
    try {
        const session = JSON.parse(getUserInfo());
        const headers = get(session, "headers", "");
        const user = get(session, "user", "");

        if(get(user,'shops')){
            const shops = get(user,'shops',[]);
            if(shops[0] == null) user['shops'] = []
        }

        if (headers) {
            setToken(headers);
            yield put({
                type: types.AUTH_USER_FOUND,
                payload: user
            });
        }

    } catch (e) {
        errorHandler(e);
    }
}

export function* saveUserDetails(action) {
    try {
        const details = action.payload;
        let user = yield call(saveUser, details.id, details.name);
        successHandler({message:"User details updated successfully"});
        user = get(user, "data.data");
        updateUserInfo(user);
        yield put({
            type: types.AUTH_USER_FOUND,
            payload: user
        })
    } catch (e) {
        errorHandler(e);
    }
}


export function* uploadAvatarImage(action) {
    try {
        const details = action.payload;
        let response = yield call(uploadAvatar, details.id, details.image);
        if(response.data.status==="success")
        {
            successHandler(response);
            let user = get(response, "data.data");
            updateUserInfo(user);
            yield put({
                type: types.AUTH_USER_FOUND,
                payload: user
            })
        }
        else{
            errorHandler(response.data);
        }
    } catch (e) {
        errorHandler(e);
    }
}


export function* uploadShopImg(action) {
    try {
        let response = yield call(uploadShopImage, action.payload);
        if(response.data.status==='success'){
            successHandler(response);
            let shop = get(response, "data.data");
            const user = JSON.parse(getUserInfo())['user'];
            user['shops'] = [shop];
            updateUserInfo(user);
            yield put({
            type: types.SHOP_UPDATED,
            payload: shop
            })
        }
        else{
            errorHandler(response.data);
        }
    } catch (e) {
       errorHandler(e);
    }
}


export function* getMailInfo(action) {
    try {
        let followups = yield call(getShopMailSettings, action.payload);
        followups = get(followups, "data.data");
        yield put({
            type: types.MAIL_INFO_FETCHED,
            payload: followups
        })
    } catch (e) {
        errorHandler(e);
    }
}

export function* getAllEmails() {
    try {
        let emails = yield call(getAllMails);
        emails = get(emails, "data.data");
        yield put({
            type: types.MAIL_ALL_MAILS_FETCHED,
            payload: emails
        })
    } catch (e) {
        errorHandler(e);
    }
}


export function* createNewEmails(action) {
    try {
        let emails = yield call(createNewMails, action.payload);
        emails = get(emails, "data.data");
        yield put({
            type: types.MAIL_ALL_MAILS_UPDATED,
            payload: emails
        })
    } catch (e) {
        errorHandler(e);
    }
}

export function* updateEmails(action) {
	const {payload} = action;
    try {
		const mails = yield select((state)=>state.Shop.mails);
        let emails = yield call(updateMails, payload['id'], payload.data );
        successHandler(emails);
		emails = get(emails, "data.data");
		let index = findIndex(mails,{id: emails['id']});
		if(index !== -1) Object.assign(mails[index],emails)

        yield put({
            type: types.MAIL_ALL_MAILS_UPDATED,
            payload: mails
        })
    } catch (e) {
        errorHandler(e);
    }
}

// Create New Lead
export function* createNewLead(action){
    const {payload}=action;
    try {
        const {data}=yield call(createLeadWidget,payload);
        if(data.status==="success"){
            yield put({
                type:types.STORE_ADD_NEW_WIDGET,
                payload:data.data
            })
        }

    } catch (e) {
        errorHandler(e);
    }
}

export function* getAllLeads(action){
    try {
        const {data}=yield call(fetchAllLeads);
        if(data.status==="success"){
            yield put({
                type:types.STORE_UPDATE_FETCHED_LEADS,
                payload:data.data
            })
        }
    } catch (e) {
        errorHandler(e);
    }
}

export function* getAllUserDetails(action){
    try {
        let response=yield call(fetchAllUserDetails);
        let user = get(response, "data.data");
        yield put({
            type:types.STORE_FETCH_ALL_USER_DETAILS,
            payload:user
        })
        yield put({
            type:types.CLEAR_CURRENT_SHOP_ID
        })
    } catch (e) {
        errorHandler(e);
    }
}

export function* setFollowup(action){
    const {payload}=action;
    try {
        let response=yield call(updateFollowup,payload['id'],payload.data);
        if(response.data.status==="success"){
            let followup = get(response, "data.data");
            yield put({
            type: types.STORE_UPDATE_FOLLOWUP,
            payload:followup
            })
        }
        else
            errorHandler(response.data);
        } catch (e) {
        errorHandler(e);
    }

}

export function* setShopDefault(action){
    try {
        let response=yield call(makeShopDefault,action.payload);
        let data=get(response, "data");
        if(data.status==="success"){
            yield put({
                type:types.UPDATE_DEFAULT_SHOP,
                payload:data.data
            })
        }
    } catch (e) {
        errorHandler(e);
    }
}

export function* getEmailTemplateInfo(action){
    const {payload}=action;
    try {
        let response=yield call(fetchEmailTemplateInfo,payload);
        let template= get(response, "data.data");
        yield put({
            type: types.STORE_UPDATE_FETCH_TEMPLATE,
            payload:template
        })
    } catch (e) {
        errorHandler(e);
    }
}

export function* modifyLead(action){
    const {payload}=action;
    try {
        const {data}=yield call(updateLead,payload.id,payload.data);
        if(data.status==="success"){
        if(payload.showMsg)
            successHandler(data);
            yield put({
                type:types.STORE_UPDATE_UPDATED_LEADS,
                payload:data.data
            })
        }
    } catch (e) {
        errorHandler(e);
    }
}

export function* createEmailFollowup(action){
    const {payload}=action;
    try {
        let response=yield call(createNewFollowup,payload);
        let data=response.data;
        if(data.status==="success"){
            let followup=data.data;
            yield put({
                type: types.STORE_ADD_NEW_FOLLOWUP,
                payload:followup
            })
        }
        else
            errorHandler(data);
    } catch (e) {
        errorHandler(e)
    }
}

export function* getSubscribers(action){
    const { payload }=action;
    try {
        const subs = yield select(state => state.Shop.subscribers);
        const len = subs.length;
        if(action.updateRequest===true){
            const nextPage = Math.floor(len / Pagination) + 1;
            let response=yield call(fetchSubscribers,payload,nextPage);
            let data=response.data;
            if(data.status==="success"){
                let subscribers=data.data;
                if(subscribers.length){
                    if(subscribers[subscribers.length - 1]['id'] !== subs[len - 1]['id']){
                        yield delay(1000);
                        yield put({
                            type: types.STORE_UPDATE_FETCHED_SUBSCRIBERS,
                            payload:subscribers
                        })
                    }
                    else{
                        yield put({
                            type: types.LOADING_COMPLETED,
                            payload:true
                        })
                    }
                    if(!subscribers.length){
                        yield put({
                        type: types.LOADING_COMPLETED,
                        payload:true
                    })
                }
            }
         }
        }
        else{
            let response=yield call(fetchSubscribers,payload);
            let data=response.data;
            if(data.status==="success"){
                let subscribers=data.data;
                yield put({
                    type: types.STORE_ADD_FETCHED_SUBSCRIBERS,
                    payload:subscribers
                })
                if(!subscribers.length){
                    yield put({
                        type: types.LOADING_COMPLETED,
                        payload:true
                })
                }
         }
        }
    } catch (e) {
        errorHandler(e);
    }
    yield put({
        type: types.HIDE_INNER_PAGE_LOADER
    })
}

export function* getSubscribersByEmail(action){
    const {payload}=action;
    try {
        let response=yield call(emailSubscribersList,payload);
        successHandler(response.data);
    } catch (e) {
        errorHandler(e);
    }
}

//Remove Shop
export function* removeSelectedShop(action){
    const {payload}=action;
    try {
        const {data}=yield call(removeShop,payload);
        if(data.status="success"){
            yield put({
                type:types.STORE_UPDATE_REMOVE_SHOP,
                payload
            })
            successHandler(data);
        }
    } catch (e) {
        errorHandler(e);
    }
}

export default function* Auth() {
    yield takeLatest(types.FIND_ACTIVE_SESSION, findSession);
    yield takeLatest(types.SAVE_USER, saveUserDetails);
    yield takeLatest(types.UPLOAD_AVATAR, uploadAvatarImage);
    yield takeLatest(types.UPLOAD_SHOP_IMAGE, uploadShopImg);
    yield takeLatest(types.SHOP_GET_DETAILS, getShopInfo);
    yield takeLatest(types.SHOP_UPDATE_DETAILS, setShopInfo);
    yield takeLatest(types.MAIL_GET_INFO, getMailInfo);
    yield takeLatest(types.MAIL_GET_ALL_MAILS, getAllEmails);
    yield takeLatest(types.MAIL_CREATE_ALL_MAILS, createNewEmails);
    yield takeLatest(types.MAIL_SET_ALL_MAILS, updateEmails);
    yield takeLatest(types.CREATE_LEAD_WIDGET,createNewLead);
    yield takeLatest(types.FETCH_ALL_LEADS,getAllLeads);
    yield takeLatest(types.UPDATE_LEAD,modifyLead);
    yield takeLatest(types.UPDATE_FOLLOWUP,setFollowup);
    yield takeLatest(types.FETCH_EMAIL_TEMPLATE_INFO,getEmailTemplateInfo);
    yield takeLatest(types.CREATE_EMAIL_FOLLOWUP,createEmailFollowup);
    yield takeLatest(types.FETCH_ALL_USER_DETAILS,getAllUserDetails);
    yield takeLatest(types.MAKE_SHOP_DEFAULT,setShopDefault);
    yield takeLatest(types.FETCH_SUBSCRIBERS,getSubscribers);
    yield takeLatest(types.EMAIL_SUBSCRIBER_LIST,getSubscribersByEmail);
    yield takeLatest(types.REMOVE_SHOP,removeSelectedShop);
}