import React, { useState, useEffect, useCallback } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import classNames from "classnames";
import { map, isEmpty } from "lodash";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { radio_scale } from "../../constants/Home";
import * as Actions from "../../store/actions";
import { options, tabs } from "../../constants/Products";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Img from "../../constants/Img";
import { caller, formatDate } from "../../utils";
import "react-datepicker/dist/react-datepicker.css";

function updateOptions(graph) {
    const op = {...options};
    op.series[0].data = map(graph, g => {
        return {
            name: g[1],
            y: g[2]
        };
    });
    return op;
}

function Products({getProducts,products,productsGraph}) {

    const [activeRadio, setActiveRadio] = useState("This week");
    const [dateEnd, setDateEnd] = useState(new Date());
    const [dateStart,setDateStart] = useState(new Date());
    const [activeTab, setActiveTab] = useState("Abandoned Cart Amount");


    const handleRadioChange =  useCallback((update = false, ip = activeRadio) => {
        const scale = ip === "custom" ?
        {
            "start_date": formatDate(dateStart),
            "end_date": formatDate(dateEnd),
        } :
        {scale: radio_scale.get(ip)}
        //caller(getProducts,scale,update);
        getProducts(scale,update);
      },[activeRadio,getProducts,dateEnd,dateStart]);


    const handleContainerOnBottom = useCallback(() => {
        handleRadioChange(true);
      },[handleRadioChange]);


    useEffect(()=>{
        handleRadioChange();
    },[handleRadioChange,getProducts,dateStart,dateEnd]);

    useBottomScrollListener(handleContainerOnBottom)
    return (
        <div className="cart-summary uk-width-5-6@m">
            <div className="uk-padding uk-border-rounded box-shadow-medium uk-background-default">
                <div className="header-bar uk-navbar">
                    <div className="uk-navbar-left report-header">Abandoned Products</div>
                    <div className="uk-navbar-right">
                        <span>Get report on </span>
                        <span className="mail">
                    <img src={Img.mail} className="uk-img" alt=""/>
                    Mail
                    </span>
                        {/* <span className="print">
              <img src={Img.print} className="uk-img" alt=""/>
              Print
            </span> */}
                    </div>
                </div>

                <ul className="uk-tab">
                    {map([...tabs.keys()], (tab, index) => (
                        <li
                            onClick={() => setActiveTab(tab)}
                            key={index}
                            className={classNames({"uk-active": activeTab === tab})}
                        >
                            <a href="##" onClick={(e)=> e.preventDefault()}>{tab}</a>
                        </li>
                    ))}
                </ul>

                <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid graph-scale">
                    {map([...radio_scale.keys()], radio => (
                        <label key={radio}>
                            <input
                                className="uk-radio"
                                type="radio"
                                name="scale"
                                onChange={() => setActiveRadio(radio)}
                                checked={radio === activeRadio}
                            />
                            {radio}
                        </label>
                    ))}
                    <div className={
                        classNames("uk-flex", activeRadio === "custom" && "uk-border-rounded box-shadow-medium custom-picker")} >
                        <label>
                            <input
                                className="uk-radio"
                                type="radio"
                                name="scale"
                                onChange={() => setActiveRadio("custom")}
                                checked={"custom" === activeRadio}/>
                                Custom period
                        </label>
                        <div style={{ display: activeRadio === "custom"  ? "block" : "none"}}>
                            <DatePicker selected={dateStart} onChange={setDateStart} />
                            <DatePicker selected={dateEnd} minDate={dateStart} onChange={setDateEnd} />
                        </div>
                    </div>
                </div>
                {!isEmpty(productsGraph[tabs.get(activeTab)]) && products[tabs.get(activeTab)].length>0 &&(
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={updateOptions(productsGraph[tabs.get(activeTab)])}
                    />
                )}

                <div className="header-bar uk-navbar">
                    <div className="uk-navbar-left report-header">
                        Abandoned cart amount
                    </div>
                    <div className="uk-navbar-right">
                        <div className="searchbar-container">
                            <form className="uk-search uk-search-default uk-width-1-1" onSubmit={(e)=>e.preventDefault()}>
                                <span className="uk-search-icon-flip" uk-search-icon=""/>
                                <input
                                    className="uk-search-input uk-border-pill"
                                    type="search"
                                    placeholder="Search items..."
                                />
                            </form>
                        </div>
                        <div>
                            <span className="sort-by-cart-value">Total cart value</span>
                            <img src={Img.dropdown} className="uk-img" alt=""/>
                        </div>
                    </div>
                </div>
                <div className="table">
                    <table className="uk-table">
                        <thead>
                        <tr>
                            <th>Product name</th>
                            <th>Opted by</th>
                            <th>Abandoned</th>
                            {/* <th>In last 5 days</th> */}
                            <th>Cost</th>
                        </tr>
                        </thead>
                        <tbody>
                        {map(products[tabs.get(activeTab)], product => (
                            <React.Fragment key={product.product_id}>
                                <tr className="box-shadow-medium border-round">
                                    <td>{product.name}</td>
                                    <td className="user-count">
                                        {product.customer_count} Users
                                    </td>
                                    <td className="user-count">
                                        {product.total_quantity} times
                                    </td>
                                    {/* <td className="user-count">2 times</td> */}
                                    <td>${product.total_amount.toLocaleString()}</td>
                                </tr>
                                <tr className="spacer"/>
                            </React.Fragment>
                        ))}
                        </tbody>
                    </table>
                    {!products[tabs.get(activeTab)].length&&
                        <div className="not-found-msg"><span uk-icon="warning"></span>
                        No Records Found...!</div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({Home}) => ({
    products: Home.abandonProducts,
    productsGraph: Home.abandonProductsGraph
});

const mapDispatchToProps = {
    getProducts: Actions.getProducts
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Products);
