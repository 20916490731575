import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import {map} from "lodash";
import * as Actions from "../../store/actions";
import { radio_scale } from "../../constants/Home";
import AbandonProduct from "../../components/AbandonProduct";
import Img from "../../constants/Img";
import { caller, formatDate, getCallerName } from "../../utils";
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import classNames from  "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    CSSTransition,
    TransitionGroup,
  } from 'react-transition-group';

function Cart({cart,products}) {
    // Radio Button state
    const [activeRadio, setActiveRadio] = useState("This week");
    const [dateEnd, setDateEnd] = useState(new Date());
    const [dateStart,setDateStart] = useState(new Date());


   const handleRadioChange =  useCallback((update = false,ip = activeRadio) => {
        const scale = ip === "custom" ?
        {
            "start_date": formatDate(dateStart),
            "end_date": formatDate(dateEnd),
        } :
        {scale: radio_scale.get(ip)}
        caller(cart,scale,update);
      },[activeRadio,cart,dateEnd,dateStart]);


    const handleContainerOnBottom = useCallback(() => {
        handleRadioChange(true);
      },[handleRadioChange]);


    useEffect(()=>{
        handleRadioChange();
    },[handleRadioChange,cart,dateStart,dateEnd]);


    useBottomScrollListener(handleContainerOnBottom)

    return (
        <div className="cart-summary uk-width-5-6@m">
            <div className="uk-padding uk-border-rounded box-shadow-medium uk-background-default">
                <div className="header-bar uk-navbar">
                    <div className="uk-navbar-left report-header">Product List</div>
                    <div className="uk-navbar-right">
                        <span>Get report on </span>
                        <span className="mail">
                        <img alt="" src={Img.mail}/>
                        Mail
                        </span>
                        {/* <span className="print">
                        <img alt="" src={Img.print}/>
                        Print
                        </span> */}
                    </div>
                </div>

                <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid graph-scale">
                    {map([...radio_scale.keys()], radio => (
                        <label key={radio}>
                            <input
                                className="uk-radio"
                                type="radio"
                                name="scale"
                                onChange={() => setActiveRadio(radio)}
                                checked={radio === activeRadio}
                            />
                            {radio}
                        </label>
                    ))}
                    <div className={
                        classNames("uk-flex", activeRadio === "custom" && "uk-border-rounded box-shadow-medium custom-picker")} >
                        <label>
                            <input
                                className="uk-radio"
                                type="radio"
                                name="scale"
                                onChange={() => setActiveRadio("custom")}
                                checked={"custom" === activeRadio}/>
                                Custom period
                        </label>
                        <div style={{ display: activeRadio === "custom"  ? "block" : "none"}}>
                            <DatePicker selected={dateStart} onChange={setDateStart} />
                            <DatePicker selected={dateEnd} minDate={dateStart} onChange={setDateEnd} />
                        </div>
                    </div>
                </div>

                <div className="header-bar uk-navbar">
                    <div className="uk-navbar-left">
                        <div className="searchbar-container">
                            <form className="uk-search uk-search-default uk-width-1-1" onSubmit={(e)=>e.preventDefault()}>
                                <span className="uk-search-icon-flip" uk-search-icon=""/>
                                <input
                                    className="uk-search-input uk-border-pill"
                                    type="search"
                                    placeholder="Search items..."
                                    onChange={() => {
                                    }}
                                />
                            </form>
                        </div>
                    </div>

                    <div className="uk-navbar-right">
                        <div>
                            <span className="sort-by-cart-value">Sort by cart value</span>
                            <img src={Img.dropdown} className="uk-img item-image" alt=""/>
                        </div>
                    </div>
                </div>
                <div className="cart-items-container uk-margin">
                    {!products.length&&
                        <div className="not-found-msg"><span uk-icon="warning"></span>
                        No Records Found...!</div>
                    }
                    <ul className="cart-list-item uk-list">
                    <TransitionGroup className="todo-list">
                    {map(products, product => (
                        <CSSTransition
                        key={product.id}
                        timeout={500}
                        classNames="trans-item"
                        >
                        <AbandonProduct key={product.id} product={product}/>
                        </CSSTransition>
                        ))}
                    </TransitionGroup>
                    </ul>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({Home}) => ({
    products: Home.abandonCart.amount_list
});

const mapDispatchToProps = {
    cart: Actions.getCart
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Cart);
